import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "../../components";
import { signOut } from "../../helpers";
import { ReactComponent as ChevronRightIcon } from "~/assets/images/chevron-right.svg";
import ChevronLeftIcon from "../../assets/images/chevron-left.svg";
import last from "lodash/last";
import NewButton from "../form/button/new-button";
import { cn } from "~/lib/utils";

export default function ProgressTracker({
  tabs,
  activeTab,
  stepIndex,
  basePath,
  onBack,
  onForward,
}) {
  const location = useLocation();
  const { t } = useTranslation();
  const activeTabIndex = useMemo(() => {
    return tabs.findIndex((tab) => tab.id === activeTab.id);
  }, [tabs, activeTab]);

  const progressTrackerTabs = useMemo(() => {
    return tabs.filter((tab) => tab.showInProgressTracker);
  }, [tabs]);
  const progressTrackerTabsIds = useMemo(() => {
    return progressTrackerTabs.map((tab) => tab.id);
  }, [progressTrackerTabs]);

  const isEnabled = (tab) =>
    tab?.id === activeTab?.id || tab.steps.some((s) => s.isVisited);

  const isNextStepVisited = useMemo(() => {
    const nextStep =
      tabs?.[activeTabIndex]?.steps?.[stepIndex + 1] ||
      tabs?.[activeTabIndex + 1]?.steps?.[0];
    return nextStep?.isVisited;
  }, [tabs, activeTabIndex, stepIndex]);

  if (!isArray(tabs) || isEmpty(tabs)) {
    return null;
  }

  const getToLink = (tab) => {
    if (!isEnabled(tab)) {
      return "#";
    }
    if (tab.steps.length > 1) {
      return `/${basePath}/${tab.id}/${tab.steps[0].id}`;
    }
    return `/${basePath}/${tab.id}`;
  };

  return (
    <div className="progress-tracker">
      {/* Desktop View */}
      <div className="relative hidden min-h-7 w-full flex-wrap items-center justify-center md:flex">
        {activeTabIndex !== 0 && last(tabs).id !== activeTab?.id && (
          <NewButton
            color="neutral"
            variant="link"
            size="small"
            type="button"
            className="absolute top-1/2 left-0 hidden w-auto -translate-y-1/2 transform text-sm font-normal md:block"
            onClick={onBack}
          >
            {<Icon src={ChevronLeftIcon} suffixLabel={t("form.goBack")} />}
          </NewButton>
        )}
        {activeTab.showInProgressTracker && (
          <div className="onboarding-width">
            <ul
              className="mx-auto mb-0 flex w-full list-none flex-row flex-wrap justify-between gap-y-4 gap-x-7 lg:gap-x-16"
              role="tablist"
            >
              {tabs
                .filter((tab) => !!tab.showInProgressTracker)
                .map((tab, i) => (
                  <li key={i} className="flex items-center whitespace-nowrap text-center">
                    <Link
                      to={getToLink(tab) + location.search}
                      className={cn(
                        "flex flex-none items-center text-sm leading-7",
                        isEnabled(tab) && "font-bold"
                      )}
                      data-toggle="tab"
                      role="tablist"
                    >
                      {tab.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        )}
        {last(tabs)?.id !== activeTab?.id && (
          <NewButton
            color="neutral"
            variant="link"
            size="small"
            type="button"
            className="absolute top-1/2 right-0 hidden w-auto -translate-y-1/2 transform text-sm font-normal tracking-widest md:block"
            onClick={signOut}
          >
            {t("common:general.signOut")}
          </NewButton>
        )}
      </div>

      {/* Mobile View */}
      <div className="flex min-h-7 items-center justify-between md:hidden">
        <div className="flex w-1/4 flex-grow-0">
          {activeTabIndex !== 0 && last(tabs).id !== activeTab?.id && (
            <Icon src={ChevronLeftIcon} imgClassName="h-3 w-3" onClick={onBack} />
          )}
        </div>
        {activeTab.showInProgressTracker && (
          <div className="flex w-2/4 flex-grow justify-center">
            <p className="text-sm font-bold text-text-tertiary-main">{activeTab.title}</p>
          </div>
        )}
        <div className="flex w-1/4 flex-grow-0 justify-end">
          {progressTrackerTabsIds.includes(activeTab?.id) &&
            last(progressTrackerTabs).id !== activeTab?.id && (
              <ChevronRightIcon
                className={`h-3 w-3 ${!isNextStepVisited ? "disabled" : ""}`}
                onClick={onForward}
              />
            )}
        </div>
      </div>
    </div>
  );
}
