import orderBy from "lodash/orderBy";
import { QueryClient, UseQueryOptions, useQuery, useQueries } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiPathParams } from "~/types/openapi-params";

interface FetchEmployeeSuperAdminProps
  extends OpenApiPathParams<"get", "/api/v1/admin/employees/{employee_u_id}"> {}

type FetchEmployeeSuperAdminReturnType = Awaited<
  ReturnType<typeof fetchEmployeeSuperAdmin>
>;

interface EmployeeAdminQueryProps extends FetchEmployeeSuperAdminProps {
  queryOptions?: UseQueryOptions<
    FetchEmployeeSuperAdminReturnType,
    unknown,
    FetchEmployeeSuperAdminReturnType
  >;
}

export type EmployeeAdminContext = {
  employee: FetchEmployeeSuperAdminReturnType;
};

export const employeeAdminQueryKey = ({ employeeUId }: FetchEmployeeSuperAdminProps) => [
  "admin",
  "employees",
  employeeUId,
];

export const fetchEmployeeSuperAdmin = async ({
  employeeUId,
}: FetchEmployeeSuperAdminProps) => {
  const { data } = await openapi.get("/api/v1/admin/employees/{employee_u_id}", {
    params: { path: { employee_u_id: employeeUId } },
  });

  return data;
};

const employeeAdminQuery = ({
  employeeUId,
  queryOptions = {},
}: EmployeeAdminQueryProps) => ({
  ...queryOptions,
  queryKey: employeeAdminQueryKey({ employeeUId }),
  queryFn: () => fetchEmployeeSuperAdmin({ employeeUId }),
});

export const employeeAdminLoader = (queryClient: QueryClient) => {
  return async ({ params }: { params: FetchEmployeeSuperAdminProps }) => {
    const query = employeeAdminQuery(params);

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useEmployeeAdmin = ({
  employeeUId,
  queryOptions = {},
}: EmployeeAdminQueryProps) => {
  return useQuery(employeeAdminQuery({ employeeUId, queryOptions }));
};

export const useEmployeesByIds = (ids: string[]) => {
  const employeeQueries = useQueries(
    (ids || []).map((id) => ({
      queryKey: employeeAdminQueryKey({ employeeUId: id }),
      queryFn: () => fetchEmployeeSuperAdmin({ employeeUId: id }),
      enabled: !!id,
      staleTime: 60 * 60 * 1000,
    }))
  );

  const employees = employeeQueries.map((query) => query.data).filter(Boolean);
  const isLoading = employeeQueries.some((query) => query.isLoading);

  return { employees, isLoading };
};

const getOrderedExternalBenefits = (data) => {
  return {
    data: orderBy(data.external_benefit_statuses, "created_at", "desc"),
  };
};

// const getEmploymentPeriods = (data) => {
//   return {
//     data: orderBy(data.employment_periods, "date_of_hire", "asc"),
//   };
// };

const getEmploymentPeriods = (inputData) => {
  // 1. Sort the periods by date_of_hire ascending
  const sorted = orderBy(inputData.employment_periods, "date_of_hire", "asc");

  // A helper to parse or safely handle date strings
  const toDate = (val) => (val ? new Date(val) : null);

  // A helper to create date strings from a Date object (or return null if no date)
  const toDateString = (val) => (val ? val.toISOString().split("T")[0] : null);

  const result = [];

  for (let i = 0; i < sorted.length; i++) {
    const current = sorted[i];
    result.push(current); // Always include the current period

    // If this isn't the last period, check for a gap to the next
    if (i < sorted.length - 1) {
      const currentTermDate = toDate(current.date_of_termination);
      const nextHireDate = toDate(sorted[i + 1].date_of_hire);

      // Only check if both are valid dates (no gap check if either is null)
      if (currentTermDate && nextHireDate) {
        // dayAfterCurrentTerm = currentTermDate + 1 day
        const dayAfterCurrentTerm = new Date(
          currentTermDate.getTime() + 24 * 60 * 60 * 1000
        );

        // If dayAfterCurrentTerm is still before nextHireDate, we have a gap
        if (dayAfterCurrentTerm < nextHireDate) {
          // dayBeforeNextHire = nextHireDate - 1 day
          const dayBeforeNextHire = new Date(
            nextHireDate.getTime() - 24 * 60 * 60 * 1000
          );

          // Insert a “gap period” using date_of_hire and date_of_termination
          result.push({
            date_of_hire: toDateString(dayAfterCurrentTerm),
            date_of_termination: toDateString(dayBeforeNextHire),
          });
        }
      }
    }
  }

  // Re-sort, just in case inserting the gap period changes the order
  return {
    data: orderBy(result, "date_of_hire", "asc"),
  };
};


export const useEmployeeExternalBenefits = ({ employeeUId }) => {
  return useQuery({
    queryFn: () => fetchEmployeeSuperAdmin({ employeeUId }),
    queryKey: ["admin", "employees", employeeUId, "external-benefits"],
    select: getOrderedExternalBenefits,
  });
};

export const useEmploymentPeriods = ({ employeeUId }) => {
  return useQuery({
    queryFn: () => fetchEmployeeSuperAdmin({ employeeUId }),
    queryKey: ["admin", "employees", employeeUId, "employment-periods"],
    select: getEmploymentPeriods,
  });
};
