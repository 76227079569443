import isNil from "lodash/isNil";
import { useState, ChangeEvent } from "react";
import { Body2 } from "../typography";
import ExclamationIcon from "../../assets/images/warning-red.svg";
import EyeIcon from "~/assets/images/eyeball.svg";
import ErrorMessage from "./error-message";
import twMerge from "~/utils/tw-merge";
import Tooltip from "../tooltip";
import QuestionMarkIcon from "../../assets/images/question-mark.svg";
import { Label } from "../ui/label";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

const classNames = {
  input: {
    base: "rounded border border-solid border-transparent focus:border-transparent ring-0 focus:ring-0 h-full p-4 bg-secondary-light_2 text-sm font-normal text-text-secondary-main w-full",
    focused: "focus:border-pen-marigold-04 focus:bg-white focus:caret-pen-marigold-04",
    error: "text-error-main border-error-main focus:border-error-main pr-8",
    disabled: "text-text-secondary-light_1 cursor-not-allowed",
  },
  leadingIcon: "absolute left-4 top-1/2 -translate-y-1/2",
  trailingIcon:
    "absolute right-4 top-1/2 -translate-y-1/2 w-5 h-5 text-text-secondary-light_1",
  description: "text-text-secondary-light_1 mt-1",
  errorMessage: "mt-1",
  errorIcon: "absolute top-1/2 -translate-y-1/2 right-4",
};

interface IconProps {
  className?: string;
}

interface TextInputProps {
  name?: string;
  type?: string;
  tooltip?: string;
  label?: string;
  placeholder?: string;
  description?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | null;
  register?: any;
  value?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  key?: string | number;
  className?: string;
  trailingIcon?: React.ComponentType<IconProps> | null;
  leadingIcon?: React.ComponentType<IconProps> | null;
  defaultValue?: any;
  autoComplete?: string;
  disabled?: boolean;
  mask?: (value: string) => string;
  min?: number | string;
  max?: number | string;
  step?: number | string;
  onlyErrorBorder?: boolean;
  inputClassName?: string;
  readOnly?: boolean;
  required?: boolean;
  [key: string]: any;
}

function TextInput({
  name = "",
  type = "text",
  tooltip = "",
  label = "",
  placeholder = "",
  description = "",
  error = null,
  register = null,
  value = null,
  onChange = null,
  key = null,
  className = "",
  trailingIcon: TrailingIcon = null,
  leadingIcon: LeadingIcon = null,
  defaultValue = null,
  autoComplete = "on",
  disabled = false,
  mask = null,
  min = null,
  max = null,
  step = null,
  onlyErrorBorder = false,
  inputClassName = "",
  readOnly = false,
  required = false,
  ...props
}: TextInputProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  function togglePasswordVisibility(): void {
    setIsPasswordVisible((prevState) => !prevState);
  }

  // format input as required (determined by mask func)
  const registerProps =
    mask && register
      ? {
          ...register,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = mask(e.target.value);
            register.onChange(e);
          },
        }
      : register;

  const isPasswordType = type === "password";
  const getInputType = (inputType: string): string => {
    if (isPasswordType) {
      return isPasswordVisible ? "text" : "password";
    }
    return inputType;
  };

  return (
    <div className={className}>
      {!isNil(label) && (
        <div className="mb-2 flex flex-row items-center gap-2">
          <Label
            required={required}
            className={` ${disabled ? "text-text-secondary-light_1" : ""}`}
          >
            {label}
          </Label>
          {tooltip && (
            <Tooltip
              textContainerClassName="mx-1 mb-0.5"
              text={
                <img
                  src={QuestionMarkIcon}
                  alt="Question"
                  className="h-4 w-4 text-gray-400"
                />
              }
              tooltipText={tooltip}
            />
          )}
        </div>
      )}
      {/* min-w-64 */}
      <div className="relative flex h-12">
        <input
          key={key}
          name={name}
          type={getInputType(type)}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          min={min}
          max={max}
          step={step}
          {...props}
          className={twMerge(
            classNames.input.base,
            disabled && classNames.input.disabled,
            !disabled && isNil(label) && classNames.input.focused,
            !isNil(error) && classNames.input.error,
            LeadingIcon && "pl-10",
            TrailingIcon && (!isNil(error) ? "pr-12" : "pr-8"),
            inputClassName
          )}
          {...(mask ? registerProps : register)}
          {...(!isNil(value) ? { value } : {})}
          {...(!isNil(onChange) ? { onChange } : {})}
          {...(!isNil(defaultValue) ? { defaultValue } : {})}
          {...(type === "date" ? { max: "2999-12-31" } : {})}
          readOnly={readOnly}
        />
        {!onlyErrorBorder && !isNil(error) && (
          <img
            src={ExclamationIcon}
            alt="Error"
            className={twMerge(
              classNames.errorIcon,
              TrailingIcon && "right-9",
              isPasswordType && "right-10"
            )}
          />
        )}
        {!isNil(LeadingIcon) && <LeadingIcon className={classNames.leadingIcon} />}
        {!isNil(TrailingIcon) && <TrailingIcon className={classNames.trailingIcon} />}
        {isPasswordType && (
          <button type="button" onClick={togglePasswordVisibility}>
            <img
              src={EyeIcon}
              alt="Toggle password visibility"
              className={`${classNames.trailingIcon} ${
                !isPasswordVisible
                  ? "text-text-secondary-light_1"
                  : "text-text-secondary-main"
              }`}
            />
          </button>
        )}
      </div>
      {!isNil(description) && (
        <Body2
          className={twMerge(
            classNames.description,
            disabled && "text-text-secondary-light_1"
          )}
        >
          {description}
        </Body2>
      )}
      {!onlyErrorBorder && !isNil(error) && (
        <ErrorMessage className={classNames.errorMessage} error={error} />
      )}
    </div>
  );
}

export default TextInput;
