import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiFetchOptions } from "~/types/openapi-params";

/**
 * Order
 */

interface FetchAdminOrderProps {
  orderUId: string;
  fetchOptions?: OpenApiFetchOptions<"get", "/api/v1/admin/orders/{order_u_id}">;
  skip?: number;
  limit?: number;
}

export const fetchAdminOrder = async ({
  orderUId,
  fetchOptions = {},
}: FetchAdminOrderProps) => {
  const { data } = await openapi.get("/api/v1/admin/orders/{order_u_id}", {
    ...fetchOptions,
    params: {
      path: {
        order_u_id: orderUId,
      },
    },
  });

  return data;
};

export const fetchOrderTransactions = async ({
  orderUId,
  fetchOptions = {},
  skip = 0,
  limit = null,
  ...filters
}: FetchAdminOrderProps) => {
  const { data } = await openapi.get("/api/v1/admin/orders/{order_u_id}/transactions", {
    ...fetchOptions,
    params: {
      path: {
        order_u_id: orderUId,
      },
      query: {
        skip,
        limit,
        ...filters,
      },
    },
  });

  return data;
};

interface AdminOrderQueryProps {
  orderUId: string;
}

interface AdminOrderTradeInstructionsQueryProps {
  orderUId: string;
  skip?: number;
  limit?: number;
  filters?: Record<string, any>;
  fetchOptions?: OpenApiFetchOptions<
    "get",
    "/api/v1/admin/orders/{order_u_id}/trade-instructions"
  >;
}

interface AdminOrderTransactionsQueryProps {
  orderUId: string;
  skip?: number;
  limit?: number;
  filters?: Record<string, any>;
  fetchOptions?: OpenApiFetchOptions<
    "get",
    "/api/v1/admin/orders/{order_u_id}/transactions"
  >;
}

const adminOrderQuery = ({ orderUId }: AdminOrderQueryProps) => ({
  queryKey: ["superuser", "order", { orderUId }],
  queryFn: () => fetchAdminOrder({ orderUId }),
  enabled: !!orderUId,
});

const orderTransactionsQuery = ({ orderUId, skip = 0, limit = null, ...filters }: AdminOrderTransactionsQueryProps) => ({
  queryKey: ["superuser", "order", { orderUId, skip, limit, ...filters }],
  queryFn: () => fetchOrderTransactions({ orderUId, skip, limit, ...filters }),
  enabled: !!orderUId,
});

export const useAdminOrder = ({ orderUId }: AdminOrderQueryProps) => {
  return useQuery(
    adminOrderQuery({
      orderUId,
    })
  );
};

export const fetchAdminOrderTradeInstructions = async ({
  orderUId,
  skip = 0,
  limit = 100,
  fetchOptions = {},
  ...filters
}: AdminOrderTradeInstructionsQueryProps) => {
  const { data } = await openapi.get(
    "/api/v1/admin/orders/{order_u_id}/trade-instructions",
    {
      ...fetchOptions,
      params: {
        path: {
          order_u_id: orderUId,
        },
        query: {
          skip,
          limit,
          ...filters,
        },
      },
    }
  );

  return data;
};

export const useAdminOrderTradeInstructions = ({
  orderUId,
  skip = 0,
  limit = 100,
  ...filters
}: AdminOrderTradeInstructionsQueryProps) => {
  return useQuery({
    queryKey: ["superuser", "order", { orderUId }, "trade_instructions", { skip, limit, ...filters }],
    queryFn: () =>
      fetchAdminOrderTradeInstructions({ orderUId, skip, limit, ...filters }),
    enabled: !!orderUId,
  });
};

export const useCustodianEncodedFile = ({ orderUId, onError }) => {
  return useQuery({
    queryKey: ["superuser", "order", { orderUId }, "custodian_file"],
    queryFn: () =>
      openapi
        .get("/api/v1/admin/orders/{order_u_id}/encoded", {
          params: {
            path: {
              order_u_id: orderUId,
            },
          },
        })
        .then((res) => res.data),
    enabled: !!orderUId,
    ...(onError ? { onError } : {}),
  });
};

export const useAdminOrderTransactions = ({ orderUId, skip = 0, limit = null, ...filters }: AdminOrderTransactionsQueryProps) => {
  return useQuery(orderTransactionsQuery({ orderUId, skip, limit, ...filters }));
};

export const adminOrderLoader = (queryClient: QueryClient) => {
  return async ({ params }: { params: { id: string } }) => {
    const query = adminOrderQuery({ orderUId: params.id });

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};
