import * as LabelPrimitive from "@radix-ui/react-label";
import * as React from "react";

import { cn } from "~/lib/utils";

interface LabelProps extends React.ComponentProps<typeof LabelPrimitive.Root> {
  required?: boolean;
  error?: boolean;
}

function Label({
  className,
  children,
  error = false,
  required = false,
  ...props
}: LabelProps) {
  return (
    <LabelPrimitive.Root
      data-slot="label"
      className={cn(
        "text-text-secondary-main leading-none text-sm font-medium select-none group-data-[disabled=true]:pointer-events-none group-data-[disabled=true]:opacity-50 peer-disabled:cursor-not-allowed peer-disabled:opacity-50",
        error && "text-destructive",
        className
      )}
      {...props}
    >
      {children}
      {required && <span className="text-error-main">*</span>}
    </LabelPrimitive.Root>
  );
}

export { Label };
