import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiFetchOptions } from "~/types/openapi-params";

interface FetchSecurityProps {
  securityId: string;
  fetchOptions?: OpenApiFetchOptions<"get", "/api/v1/admin/securities/{security_id}">;
}

export const fetchSecurity = async ({
  securityId,
  fetchOptions = {},
}: FetchSecurityProps) => {
  const { data } = await openapi.get("/api/v1/admin/securities/{security_id}", {
    ...fetchOptions,
    params: {
      path: {
        security_id: Number(securityId),
      },
    },
  });
  return data;
};

interface SecurityQueryProps {
  securityId: string;
}

const securityQuery = ({ securityId }: SecurityQueryProps) => ({
  queryKey: ["security", { securityId }],
  queryFn: () => fetchSecurity({ securityId }),
  enabled: !!securityId,
});

export const useSecurity = ({ securityId }: SecurityQueryProps) => {
  return useQuery(
    securityQuery({
      securityId,
    })
  );
};

export const securityLoader = (queryClient: QueryClient) => {
  return async ({ params }: { params: { id: string } }) => {
    const query = securityQuery({ securityId: params.id });
    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};
