import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import useCompany from "~/hooks/dashboards/queries/use-company";
import { currentUserSelector } from "~/redux/selectors";
import apiInstance from "~/services/axios-instance";

export const useMeta = () => {
  const user = useSelector(currentUserSelector);
  const { data: company } = useCompany(user.company_id, true);
  const channelSource = company?.channel_source;

  let {isLoading, data, ...query} =  useQuery({
    queryKey: ["meta", channelSource],
    queryFn: async () => {
      const params = channelSource
        ? {
            channel_source: channelSource,
          }
        : null;
      const { data } = await apiInstance.get("/meta", { params: params });
      return data;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: Infinity,
  });
  if (isLoading) {
    data = {};
  }
  return {isLoading, data, ...query}
};

export const useIsProduction = (defaultValue = true): boolean => {
  // true as a safe default to prevent showing dev-only features during loading
  const { data: { is_production = defaultValue } = {} } = useMeta();
  return is_production;
};
