import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TextInput from "./text-input";
import { Select } from ".";
import { US_STATE_OPTIONS } from "../../constants";
import { isFieldRequired, masksMap } from "../../utils";
import { addressSchema } from "../../schema/general";

export default function AddressGroup({
  errors,
  register,
  watch,
  setValue,
  placeholders = {},
  disabled = false,
  required = false,
}) {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        label={t("common:general.street")}
        register={register("address")}
        error={errors?.address}
        className="mt-6"
        disabled={disabled}
        required={isFieldRequired("address", addressSchema)}
      />
      <TextInput
        label={t("common:general.city")}
        register={register("city")}
        error={errors?.city}
        placeholder={placeholders?.city}
        className="mt-6"
        disabled={disabled}
        required={isFieldRequired("city", addressSchema)}
      />
      <div className="mt-6 flex w-full flex-col flex-wrap items-stretch justify-items-stretch gap-5 sm:flex-row sm:items-start">
        <Select
          label={t("common:general.state")}
          error={errors?.state}
          options={US_STATE_OPTIONS}
          value={watch("state")}
          setValue={(val) => setValue("state", val, { shouldValidate: true })}
          required={isFieldRequired("state", addressSchema)}
          className="flex-1"
          disabled={disabled}
        />
        <TextInput
          label={t("common:general.zip")}
          register={register("zip")}
          mask={masksMap.zip}
          error={errors?.zip}
          placeholder={placeholders?.zip}
          className="flex-1"
          disabled={disabled}
          required={isFieldRequired("zip", addressSchema)}
        />
      </div>
    </>
  );
}

AddressGroup.propTypes = {
  title: PropTypes.string,
  errors: PropTypes.object,
  register: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};
