import { FC } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import Sheet from "@mui/joy/Sheet";
import IconButton from "@mui/joy/IconButton";
import { listItemButtonClasses } from "@mui/joy/ListItemButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import ArticleIcon from "@mui/icons-material/Article";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ReportIcon from "@mui/icons-material/Report";
import FolderIcon from "@mui/icons-material/Folder";
import PaymentsIcon from "@mui/icons-material/Payments";
import EmailIcon from "@mui/icons-material/Email";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ViewListIcon from "@mui/icons-material/ViewList";
import PaidIcon from "@mui/icons-material/PaidOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalanceOutlined";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import WalletIcon from "@mui/icons-material/Wallet";
import LogoutIcon from "@mui/icons-material/Logout";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

import Logo from "~/components/logo";
import { SidebarListItem } from "~/components/super-admin/layout/sidebar/sidebar-list-item";
import { SidebarNestedList } from "~/components/super-admin/layout/sidebar/sidebar-nested-list";
import { useJoyTheme } from "~/hooks/use-joy-theme";
import { signOut as logOut } from "~/helpers";
import { ListSubheader } from "@mui/joy";
import { useMeta } from "~/hooks/shared/queries/use-meta";

const CustodianNestedListItems = () => {
  const { t } = useTranslation();

  return (
    <>
      <SidebarListItem icon={<PaidIcon />} href="/external-activities">
        {t("admin:sidebar.cashActivity")}
      </SidebarListItem>
    </>
  );
};

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
}

export const Sidebar: FC<SidebarProps> = ({ onToggle, isOpen }) => {
  const theme = useJoyTheme();
  const { t } = useTranslation();
  const { data: meta } = useMeta();

  return (
    <Sheet
      variant={theme.sidebar.variant}
      color={theme.sidebar.color}
      sx={{
        position: "sticky",
        top: 0,
        transition: "padding 0.4s, width 0.4s",
        zIndex: 20,
        height: "100dvh",
        width: {
          xs: isOpen
            ? `${theme.sidebar.width.open}px`
            : `${theme.sidebar.width.closedXs}px`,
          sm: isOpen
            ? `${theme.sidebar.width.open}px`
            : `${theme.sidebar.width.closed}px`,
        },
        px: {
          xs: isOpen ? 2 : 0.5,
          sm: isOpen ? 2 : 1,
        },
        py: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          gap: 2,
          justifyContent: isOpen ? "space-between" : "center",
          pl: isOpen ? 1 : 0,
        }}
      >
        {isOpen && <Logo type="penelope" variant="full" />}
        <IconButton onClick={onToggle}>
          {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{ "--ListItem-radius": "var(--joy-radius-sm)", "--List-gap": "2px" }}
        >
          <SidebarListItem icon={<DashboardIcon fontSize="small" />} href="/">
            {t("admin:sidebar.dashboard")}
          </SidebarListItem>

          <SidebarListItem icon={<BusinessIcon fontSize="small" />} href="/companies">
            {t("admin:sidebar.companies")}
          </SidebarListItem>

          <ListSubheader sx={{ fontWeight: "800" }}>Trading</ListSubheader>

          <SidebarListItem icon={<WalletIcon fontSize="small" />} href="/investments">
            {t("admin:sidebar.investments")}
          </SidebarListItem>
          <SidebarListItem
            icon={<CurrencyExchangeIcon fontSize="small" />}
            href="/trades"
          >
            {t("admin:sidebar.trades")}
          </SidebarListItem>
          <SidebarListItem icon={<ViewListIcon fontSize="small" />} href="/orders">
            {t("admin:sidebar.orders")}
          </SidebarListItem>
          <SidebarListItem
            icon={<SwapHorizIcon fontSize="small" />}
            href="/transfers/create"
          >
            {t("admin:sidebar.transfers")}
          </SidebarListItem>

          <ListSubheader sx={{ fontWeight: "800" }}>In</ListSubheader>

          <SidebarListItem icon={<ReceiptIcon fontSize="small" />} href="/payrolls">
            {t("admin:sidebar.payrolls")}
          </SidebarListItem>
          <SidebarListItem
            icon={<RequestQuoteIcon fontSize="small" />}
            href="/rollover-requests"
          >
            {t("admin:sidebar.rolloverRequests")}
          </SidebarListItem>

          <ListSubheader sx={{ fontWeight: "800" }}>Out</ListSubheader>

          <SidebarListItem
            icon={<PaymentsIcon fontSize="small" />}
            href="/distribution-requests"
          >
            {t("admin:sidebar.distributionRequests")}
          </SidebarListItem>

          <SidebarListItem icon={<UpdateOutlinedIcon fontSize="small" />} href="/rmd">
            {t("admin:sidebar.rmd")}
          </SidebarListItem>

          <ListSubheader sx={{ fontWeight: "800" }}>Plan management</ListSubheader>
          {meta.enable_loans && (
            <SidebarListItem icon={<RequestQuoteIcon fontSize="small" />} href="/loans">
              {t("admin:sidebar.loanRequests")}
            </SidebarListItem>
          )}
          <SidebarListItem icon={<ArticleIcon fontSize="small" />} href="/documents">
            {t("admin:sidebar.documents")}
          </SidebarListItem>
          <SidebarListItem icon={<EmailIcon fontSize="small" />} href="/invites">
            {t("admin:sidebar.invitations")}
          </SidebarListItem>
          {meta.enable_notifications && (
            <SidebarListItem
              icon={<NotificationsIcon fontSize="small" />}
              href="/notifications"
            >
              {t("admin:sidebar.notifications")}
            </SidebarListItem>
          )}
          <SidebarListItem icon={<ReportIcon fontSize="small" />} href="/alert-groups">
            {t("admin:sidebar.alertGroups")}
          </SidebarListItem>

          <Divider />

          {/*<SidebarListItem icon={<InsightsIcon />} href="/financials">
            {t("admin:sidebar.financials")}
          </SidebarListItem>*/}

          <ListSubheader sx={{ fontWeight: "800" }}>Reporting</ListSubheader>

          <SidebarListItem icon={<FolderIcon fontSize="small" />} href="/reports">
            {t("admin:sidebar.reports")}
          </SidebarListItem>

          <SidebarListItem
            icon={<DownloadOutlinedIcon fontSize="small" />}
            href="/data-exchange"
          >
            {t("admin:sidebar.importExport")}
          </SidebarListItem>

          {/*<SidebarListItem icon={<WorkIcon />} href="/jobs">
            {t("admin:sidebar.jobs")}
          </SidebarListItem>*/}

          {meta.enable_demos && (
            <>
              <Divider />
              <SidebarListItem icon={<CoPresentIcon fontSize="small" />} href="/demos">
                {t("admin:sidebar.demos")}
              </SidebarListItem>
              <Divider />
            </>
          )}

          {/**
           * Custodian list items
           * ----
           * When the sidebar is closed, we hide the "Custodian" button and expand
           * the list so that every item is visible.
           * */}
          {false && isOpen ? (
            <SidebarNestedList
              icon={<AccountBalanceIcon fontSize="small" />}
              label={t("admin:sidebar.custodian")}
            >
              <List sx={{ gap: 0.5, mt: 1 }}>
                <CustodianNestedListItems />
              </List>
            </SidebarNestedList>
          ) : (
            <CustodianNestedListItems />
          )}

          <Divider />

          <SidebarListItem icon={<LogoutIcon fontSize="small" />} onClick={logOut}>
            {t("admin:sidebar.logOut")}
          </SidebarListItem>
        </List>
      </Box>
    </Sheet>
  );
};
