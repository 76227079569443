import { ResponseType } from "axios";
import fileDownload from "js-file-download";
import apiInstance from "./axios-instance";

export const BASE_URL =
  import.meta.env.VITE_API_BASE_URL || "http://localhost:8888/api/v1";

const axios = apiInstance;

export const getCompany = async (compId) => {
  const { data } = await axios.get(`${BASE_URL}/companies/${compId}`);
  return data;
};

export const updateCompany = async (
  companyId,
  companyIn,
  headers = { "Content-Type": "application/json" }
) => {
  const { data } = await axios.patch(`${BASE_URL}/companies/${companyId}`, companyIn, {
    headers,
  });
  return data;
};

export const updateCompanyBillingEmail = async (companyId, payload) => {
  const { data } = await axios.patch(
    `${BASE_URL}/billing/companies/${companyId}`,
    payload
  );
  return data;
};

export const createEmployee = async (companyId, employee) => {
  const { data } = await axios.post(
    `${BASE_URL}/companies/${companyId}/employees`,
    employee
  );
  return data;
};

export const updateEmployee = async (companyId, employeeId, employee) => {
  const { data } = await axios.patch(
    `${BASE_URL}/companies/${companyId}/employees/${employeeId}`,
    employee
  );
  return data;
};

export const updateIndividual = async (individualId, individual) => {
  const { data } = await axios.patch(
    `${BASE_URL}/individuals/${individualId}`,
    individual
  );
  return data;
};

export const inviteAdmin = async (companyId, admin) => {
  const { data } = await axios.post(
    `${BASE_URL}/companies/${companyId}/admins/invite`,
    admin
  );
  return data;
};

export const transferOwner = async (companyId, userId) => {
  const { data } = await axios.post(
    `${BASE_URL}/companies/${companyId}/admins/${userId}/owner`
  );
  return data;
};

export const resendExpiredInvite = async ({ inviteCode }) => {
  const { data } = await axios.post(
    `${BASE_URL}/invite/resend-expired?code=${encodeURIComponent(inviteCode)}`
  );
  return data;
};

export const getCurrentUser = async () => {
  const { data, headers } = await axios.get(`${BASE_URL}/users/me`);
  if (headers["x-real-user-id"]) {
    data.realUserId = headers["x-real-user-id"];
  }
  data.isDemoUser = !!headers["x-demo-user"];
  return data;
};

export const updateUser = async (user, options = {}) => {
  const { data } = await axios.patch(`${BASE_URL}/users/me`, user, options);
  return data;
};

export const uploadCensus = async (companyId, file) => {
  const { data } = await axios.post(
    `${BASE_URL}/companies/${companyId}/uploads/census`,
    file
  );
  return data;
};

export const downloadFile = async (
  fileName,
  url,
  headers = {},
  signal = null,
  params = {}
) => {
  const response = await axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers,
    signal,
    params,
  });
  fileDownload(response.data, fileName);
};

export const getDownloadCensusTemplateUrl = async (companyId, fileName) => {
  const url = `${BASE_URL}/companies/${companyId}/downloads/census-template`;
  await downloadFile(fileName, url);
};

export const getDownloadCensusCSVUrl = async (
  companyId,
  finchIdentityId,
  fileName,
  signal
) => {
  const url = `${BASE_URL}/payroll-integration/directory/raw?company_id=${companyId}&finch_identity_id=${finchIdentityId}`;
  await downloadFile(
    fileName,
    url,
    {
      Accept: "text/csv",
    },
    signal
  );
};

export const getDownloadPayrollTemplateUrl = async (companyId, fileName) => {
  const url = `${BASE_URL}/companies/${companyId}/downloads/payroll-template`;
  await downloadFile(fileName, url);
};

export const downloadAdminReport = async (
  reportType,
  data,
  fileName,
  format = "excel"
) => {
  if (!["csv", "excel"].includes(format)) {
    throw new Error("Format should either be excel or csv");
  }
  if (fileName) {
    fileName += format === "excel" ? ".xlsx" : ".csv";
  }
  const resource = reportType
    .split(/\.?(?=[A-Z])/)
    .join("-")
    .toLowerCase();

  const response = await axios.post(`${BASE_URL}/admin/data-exchange/${resource}`, data, {
    responseType: format === "excel" ? "blob" : ("csv" as ResponseType),
    headers: {
      accept:
        format === "excel"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : "text/csv",
    },
  });

  const defaultFilename = response.headers["content-disposition"]
    ? response.headers["content-disposition"].split("filename=")[1]?.replace(/"/g, "")
    : `${resource}.${format === "excel" ? "xlsx" : "csv"}`;

  await fileDownload(response.data, fileName || defaultFilename);
};

export const getDownloadDeferral = async (companyId, fileName) => {
  const url = `${BASE_URL}/companies/${companyId}/downloads/deferral`;
  await downloadFile(fileName, url);
};

export const validateInviteCode = async (code) => {
  const { data } = await axios.post(`${BASE_URL}/invite/validate`, {
    invitation_id: code,
  });
  return data;
};

export const claimInvitationCode = async (code, email, sub) => {
  const { data } = await axios.post(
    `${BASE_URL}/invite/claim`,
    {
      invitation_code: code,
      claimed_by: email,
      claimed_by_sub: sub,
    },
    { headers: { "x-api-key": "foo" } }
  );
  return data;
};

export const getFile = async (title, objectPath) => {
  const ext = objectPath.split(".").pop();
  const downloadedFileName = `${title}.${ext}`;
  const url = `${BASE_URL}/files/file?path=${objectPath}`;
  downloadFile(downloadedFileName, url);
};

export const getFileNew = async (objectPath) => {
  const fileName = objectPath.split("/").pop();
  const url = `${BASE_URL}/files/file?path=${objectPath}`;
  downloadFile(fileName, url);
};

export const getFileUrl = async (objectPath) => {
  const url = `${BASE_URL}/files/file?path=${objectPath}`;
  const response = await axios({
    url: url,
    method: "GET",
    responseType: "blob",
  });
  return response;
};

export const setupBTCAccount = async (companyId) => {
  const { data } = await axios.post(`${BASE_URL}/companies/${companyId}/setup-account`);
  return data;
};

export const validateCompanyName = async (name) => {
  const { data } = await axios.post(`${BASE_URL}/companies/validators/name`, {
    name: name,
  });
  return data;
};

export const getCompanyEmployeesSuperAdmin = async ({
  companyId,
  status,
  skip = 0,
  limit = 100,
  search,
  config = null,
  sort,
}) => {
  const { data } = await axios.get(`${BASE_URL}/admin/companies/${companyId}/employees`, {
    params: {
      status,
      skip,
      limit,
      search,
      sort,
    },
    ...(config || {}),
  });
  return data;
};

export const syncFinch = async (companyId) => {
  const { data } = await axios.post(`${BASE_URL}/payroll-integration/jobs/sync`, null, {
    params: {
      company_id: companyId,
    },
  });
  return data;
};

export const sendDistribution = async (distributionRequestId) => {
  const { data } = await axios.post(
    `${BASE_URL}/admin/distribution-requests/${distributionRequestId}/send`
  );
  return data;
};

export const backdateLoan = async ({ loanUid, months = 6 }) => {
  const { data } = await axios.post(`${BASE_URL}/loans/${loanUid}/backdate`, null, {
    params: { months },
  });
  return data;
};

export const createRemediation = async (payload) => {
  const { data } = await axios.post(`${BASE_URL}/admin/remediation`, payload);
  return data;
};

export const addExternalActivity = async (activityId, requestId) => {
  const { data } = await axios.post(
    `${BASE_URL}/admin/external-activities/${activityId}/match`,
    null,
    {
      params: { distro_request_id: requestId, activity_id: activityId },
    }
  );
  return data;
};

export const reconcileCashActivity = async ({ u_id, activityId, amount, quantity }) => {
  const { data } = await axios.post(`${BASE_URL}/admin/orders/${u_id}/reconcile`, null, {
    params: { activity_id: activityId, amount, quantity },
  });
  return data;
};

export const addExternalActivityToRollover = async (activityId, requestLineId) => {
  const { data } = await axios.post(
    `${BASE_URL}/admin/rollover-activities/${activityId}/match`,
    null,
    {
      params: { rollover_line_request_id: requestLineId, activity_id: activityId },
    }
  );
  return data;
};

export default axios;

export const checkIfEmailExists = async (email: string) => {
  try {
    await axios.post(`${BASE_URL}/users/check-email`, { email });
    return true;
  } catch (err) {
    if (err.response.status === 404) {
      return false;
    } else {
      throw err;
    }
  }
};
