import PropTypes from "prop-types";
import twMerge from "~/utils/tw-merge";

export const variants = {
  header1: "header-1",
  header2: "header-2",
  header3: "header-3",
  header4: "header-4",
  header5: "header-5",
  header6: "header-6",
  subHeader1: "sub-header-1",
  subHeader2: "sub-header-2",
  label: "label",
  body1: "body-1",
  body2: "body-2",
  body3: "body-3",
  helper: "helper",
  span: "span",
};

export const colors = {
  primary: "text-text-secondary-main",
  secondary: "text-text-secondary-light_1",
};

function Typography({
  variant = variants.body2,
  text,
  color = "primary",
  className = "",
  ...props
}) {
  const isVariant = (num) => variant === num;

  return (
    <>
      {isVariant(variants.header1) && (
        <h1
          className={`text-3xl font-medium leading-10 ${twMerge(
            colors[color],
            className
          )}`}
          {...props}
        >
          {text}
        </h1>
      )}

      {isVariant(variants.header2) && (
        <h2
          className={`text-2xl font-medium ${twMerge(colors[color], className)}`}
          {...props}
        >
          {text}
        </h2>
      )}

      {isVariant(variants.header3) && (
        <h3
          className={`text-xl font-medium ${twMerge(colors[color], className)}`}
          {...props}
        >
          {text}
        </h3>
      )}

      {isVariant(variants.header4) && (
        <h4 className={twMerge(colors[color], className)} {...props}>
          {text}
        </h4>
      )}

      {isVariant(variants.header5) && (
        <h5 className={twMerge(colors[color], className)} {...props}>
          {text}
        </h5>
      )}

      {isVariant(variants.header6) && (
        <h6 className={twMerge(colors[color], className)} {...props}>
          {text}
        </h6>
      )}

      {isVariant(variants.subHeader1) && (
        <h6
          className={twMerge("text-base font-medium leading-5", colors[color], className)}
          {...props}
        >
          {text}
        </h6>
      )}

      {isVariant(variants.subHeader2) && (
        <h6 className={twMerge(colors[color], className)} {...props}>
          {text}
        </h6>
      )}

      {isVariant(variants.body1) && (
        <div
          className={`${twMerge("text-base font-normal", colors[color], className)}`}
          {...props}
        >
          {text}
        </div>
      )}

      {isVariant(variants.label) && (
        <label
          className={`block text-sm font-medium leading-4.5 ${twMerge(
            colors[color],
            className
          )}`}
          {...props}
        >
          {text}
        </label>
      )}

      {isVariant(variants.body2) && (
        <div
          className={twMerge("text-sm font-normal", colors[color], className)}
          {...props}
        >
          {text}
        </div>
      )}
      {isVariant(variants.body3) && (
        <div
          className={`text-xxs font-normal ${twMerge(colors[color], className)}`}
          {...props}
        >
          {text}
        </div>
      )}

      {isVariant(variants.helper) && (
        <p
          className={`text-xs font-normal ${twMerge(colors[color], className)}`}
          {...props}
        >
          {text}
        </p>
      )}

      {isVariant(variants.span) && (
        <span className={twMerge(colors[color], className)} {...props}>
          {text}
        </span>
      )}
    </>
  );
}

export default Typography;

Typography.propTypes = {
  variant: PropTypes.oneOf(Object.values(variants)),
  text: PropTypes.node,
  className: PropTypes.string,
};
