import { addDays, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { formatDate } from "~/utils";
import DefaultLayout from "~/layouts/default-layout";
import Footer from "../dashboard/footer";
import { getProviderSupportArticles } from "~/constants";
import { useMeta } from "~/hooks/shared/queries/use-meta";
import { getDashboardFooter } from "~/constants";

const DashboardClosed = ({ startDate, endDate, company }) => {
  const { t } = useTranslation();
  const { data: meta } = useMeta();
  const endDatePlusOne = addDays(parseISO(endDate), 1);
  const message = t("dashboard:dashboard.employee.blackout.dashboardClosed", {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    endDatePlusOne: formatDate(endDatePlusOne),
    supportArticleBlackoutPeriod: getProviderSupportArticles().blackoutPeriod,
    providerName: meta.provider_name,
  });

  return (
    <DefaultLayout showProgressCircle={false} showSignOut>
      <main className="flex flex-1 items-center justify-center">
        <div
          className="m-auto max-w-md rounded-lg bg-white p-4 text-text-secondary-main"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </main>
      <Footer
        items={getDashboardFooter({
          t,
          channelSource: company.channel_source,
          isBlackout: true,
        })}
        logoColor="fill-footer-logo"
        className="w-full lg:w-full"
      />
    </DefaultLayout>
  );
};
export default DashboardClosed;
