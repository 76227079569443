import { UIMatch as BaseUIMatch, useMatches } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useMeta } from "~/hooks/shared/queries/use-meta";

interface UIMatch extends BaseUIMatch {
  handle: {
    breadcrumb?: string | ((data: BaseUIMatch["data"]) => string);
    meta?: {
      title:
        | { key: string }
        | ((data: BaseUIMatch["data"]) => {
            value?: string;
            key?: string;
            options?: any;
          });
    };
  };
}

const isBreadcrumb = (match: UIMatch): match is UIMatch => {
  return typeof match.handle === "object" && "meta" in match.handle;
};

export const Head = () => {
  const { t } = useTranslation();
  const matches = useMatches();
  const { data: meta } = useMeta();
  const metaData = matches.filter(isBreadcrumb);

  const metaTitle = metaData.map((metaItem) => {
    const title = metaItem.handle.meta.title;
    if (typeof title === "function") {
      const data = title(metaItem.data);
      const text = data.value ? data.value : t(data.key, data.options);
      return text;
    }
    return t(title.key);
  });

  useEffect(() => {
    document.title =
      metaData.length === 0
        ? meta.provider_name
        : `${metaTitle.join(" - ")} | ${meta.provider_name}`;
  }, [metaData.length, metaTitle, meta.provider_name]);

  return null;
};
