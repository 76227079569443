import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMeta } from "~/hooks/shared/queries/use-meta";

export function useTitle(title) {
  const { t } = useTranslation();
  const { data: meta } = useMeta();
  const translatedTitle = t("common:metaTitles.admin.general", {
    pageTitle: title,
    providerName: meta.provider_name,
    interpolation: { escapeValue: false },
  });

  useEffect(() => {
    if (!title) {
      document.title = t("common:metaTitles.employer.provider", {
        providerName: meta.provider_name,
        interpolation: { escapeValue: false },
      });
      return;
    }

    document.title = translatedTitle;
  }, [meta.provider_name, t, title, translatedTitle]);
}
