import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useBanner } from "~/components/dashboard/context/banner-context";
import { getDashboardFooter } from "~/constants";
import { currentUserSelector } from "~/redux/selectors";
import { formatDate, formatLegalName } from "~/utils";
import { employeeDashboardMenuOptions } from "~/utils/employee";
import Footer from "../../components/dashboard/footer";
import DashboardLayout2 from "../../layouts/dashboard-layout2";
import useCompany from "~/hooks/dashboards/queries/use-company";
import { getProviderSupportArticles } from "~/constants";

export default function EmployeeDashboard() {
  const { t } = useTranslation();
  const currentUser = useSelector(currentUserSelector);
  const { data: company } = useCompany(currentUser?.company_id);
  const company_blackout_ended = currentUser.company_blackout_ended;
  const { addMessage, removeMessage } = useBanner();
  const accountId = currentUser.individual.account.id;
  const isEmployeeOnboarded = !!currentUser.individual.employee.is_onboarded;

  useEffect(() => {
    if (currentUser.company_blackout_start_date) {
      let message = t("dashboard:dashboard.employee.blackout.employeeBannerAfter", {
        supportArticleBlackoutPeriod: getProviderSupportArticles().blackoutPeriod,
      });
      if (!company_blackout_ended) {
        message = t("dashboard:dashboard.employee.blackout.employeeBannerBefore", {
          startDate: formatDate(currentUser.company_blackout_start_date),
          endDate: formatDate(currentUser.company_blackout_end_date),
          supportArticleBlackoutPeriod: getProviderSupportArticles().blackoutPeriod,
        });
      }
      addMessage({
        id: "employeeBlackoutBanner",
        message: <p dangerouslySetInnerHTML={{ __html: message }} />,
        isGlobal: true,
        onClose: () => removeMessage("employeeBlackoutBanner"),
      });
    }
  }, [currentUser, company_blackout_ended, removeMessage, addMessage, t]);

  const commonProps = {
    data: currentUser,
    company,
    accountId,
  };

  if (!isEmployeeOnboarded && !company_blackout_ended) {
    return <Navigate to="onboarding" />;
  } else {
    return (
      <DashboardLayout2
        variant="employee"
        navigation={employeeDashboardMenuOptions(t)}
        name={formatLegalName(currentUser.individual)}
      >
        <Outlet context={{ ...commonProps }} />
        <Footer
          items={getDashboardFooter({ t, channelSource: company.channel_source })}
          logoColor="fill-footer-logo"
        />
      </DashboardLayout2>
    );
  }
}
