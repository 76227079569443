import { UIMatch as BaseUIMatch, useMatches } from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import JoyBreadcrumbs from "@mui/joy/Breadcrumbs";
import JoyLink from "@mui/joy/Link";
import { Link } from "react-router-dom";

interface UIMatch extends BaseUIMatch {
  handle: {
    breadcrumb: string | ((data: BaseUIMatch["data"]) => string);
  };
}

const isBreadcrumb = (match: BaseUIMatch): match is UIMatch => {
  return typeof match.handle === "object" && "breadcrumb" in match.handle;
};

const renderBreadCrumbs = (breadcrumbs) =>
  breadcrumbs.map((breadcrumb, i) => {
    return Array.isArray(breadcrumb.handle.breadcrumb) ? (
      breadcrumb.handle.breadcrumb.map((e) => (
        <JoyLink
          key={
            typeof e.pathname === "function" ? e.pathname(breadcrumb.data) : e.pathname
          }
          component={Link}
          to={typeof e.pathname === "function" ? e.pathname(breadcrumb.data) : e.pathname}
          underline="none"
          color={i === breadcrumb.handle.breadcrumb.length - 1 ? "primary" : "neutral"}
        >
          {typeof e.breadcrumb === "string"
            ? e.breadcrumb
            : e.breadcrumb(breadcrumb.data)}
        </JoyLink>
      ))
    ) : (
      <JoyLink
        key={breadcrumb.pathname}
        component={Link}
        to={breadcrumb.pathname}
        underline="none"
        color={i === breadcrumbs.length - 1 ? "primary" : "neutral"}
      >
        {typeof breadcrumb.handle.breadcrumb === "string"
          ? breadcrumb.handle.breadcrumb
          : breadcrumb.handle.breadcrumb(breadcrumb.data)}
      </JoyLink>
    );
  });

export const Breadcrumbs = () => {
  const matches = useMatches();
  const breadcrumbs = matches.filter(isBreadcrumb);

  if (breadcrumbs.length === 0) return null;

  return (
    <JoyBreadcrumbs
      size="sm"
      aria-label="breadcrumbs"
      separator={<ChevronRightRoundedIcon fontSize="small" />}
      sx={{ p: 0, mb: 2.5 }}
    >
      <JoyLink component={Link} to="/" underline="none" color="neutral" aria-label="Home">
        <HomeRoundedIcon fontSize="small" />
      </JoyLink>
      {renderBreadCrumbs(breadcrumbs)}
    </JoyBreadcrumbs>
  );
};
