import { addDays, parseISO } from "date-fns";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Loading } from "~/components";
import { useBanner } from "~/components/dashboard/context/banner-context";
import Footer from "~/components/dashboard/footer";
import { getDashboardFooter, getProviderSupportArticles } from "~/constants";
import useCompany from "~/hooks/dashboards/queries/use-company";
import DashboardLayout2 from "~/layouts/dashboard-layout2";
import { currentUserSelector } from "~/redux/selectors";
import { formatDate } from "~/utils";
import { getSideMenuOptions } from "~/utils/employer";
import { hasLoans } from "~/utils/plan-config";

function EmployerDashboard() {
  const { t } = useTranslation();
  const currentUser = useSelector(currentUserSelector);
  const {
    company_id: companyId,
    company_blackout_start_date,
    company_blackout_started,
    company_blackout_end_date,
    company_blackout_ended,
  } = currentUser;
  const { data: currentCompany } = useCompany(companyId);
  const { addMessage, removeMessage } = useBanner();

  useEffect(() => {
    if (company_blackout_start_date) {
      let message;
      if (!company_blackout_ended) {
        const endDatePlusOne = addDays(parseISO(company_blackout_end_date), 1);
        message = t(
          !company_blackout_started
            ? "dashboard:dashboard.employer.blackout.employerBannerBefore"
            : "dashboard:dashboard.employer.blackout.employerBannerDuring",
          {
            startDate: formatDate(company_blackout_start_date),
            endDate: formatDate(company_blackout_end_date),
            endDatePlusOne: formatDate(endDatePlusOne),
            supportArticleBlackoutPeriod: getProviderSupportArticles().blackoutPeriod,
          }
        );
      } else {
        message = t("dashboard:dashboard.employer.blackout.employerBannerAfter", {
          supportArticleBlackoutPeriod: getProviderSupportArticles().blackoutPeriod,
        });
      }
      addMessage({
        id: "employerBlackoutBanner",
        message: <p dangerouslySetInnerHTML={{ __html: message }} />,
        isGlobal: true,
        onClose: () => removeMessage("employerBlackoutBanner"),
      });
    }
  }, [
    t,
    addMessage,
    removeMessage,
    company_blackout_start_date,
    company_blackout_end_date,
    company_blackout_started,
    company_blackout_ended,
  ]);

  const props = {
    data: currentCompany,
    companyId,
  };

  if (!currentCompany) {
    return <Loading fullScreen />;
  }

  const planAllowsLoans = hasLoans(currentCompany.retirement_plan.current_config.config);

  return (
    <DashboardLayout2
      variant="employer"
      navigation={getSideMenuOptions(currentCompany.enrollment_state, t, planAllowsLoans)}
      name={currentCompany.name}
    >
      <Outlet context={{ ...props }} />
      <Footer
        items={getDashboardFooter({ t, channelSource: currentCompany.channel_source })}
        logoColor="fill-footer-logo"
      />
    </DashboardLayout2>
  );
}

export default EmployerDashboard;
