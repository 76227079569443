import { useNavigate } from "react-router-dom";
import { Body2 } from "../../components/typography";
import { LogoutButton, NavMenuItem } from "./menu-item";
import { signOut } from "../../helpers";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import { useTranslation } from "react-i18next";
import { SalesDemoSwitch } from "~/components/sales-demo-switch";
import Logo from "~/components/logo";
import { useIsProduction } from "~/hooks/shared/queries/use-meta";

const Sidebar = ({ navigation, name, variant }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isProduction = useIsProduction();

  return (
    <div className="flex flex-grow flex-col overflow-y-auto overflow-x-hidden bg-navbar-bg pt-5">
      <div
        className="flex flex-shrink-0 cursor-pointer items-center fill-navbar-text_selected sm:px-2.5 sm:pl-2 lg:pl-3.5 lg:px-4"
        onClick={() => navigate("/")}
      >
        <div className="w-full px-1">
          <Logo type="penelope" variant="full" className="hidden lg:block" />
          <Logo type="penelope" variant="small" className="block lg:hidden" />
        </div>
      </div>
      <Body2 className="mt-5 hidden min-h-8 font-bold text-navbar-text sm:px-2.5 lg:block lg:px-4">
        {!isProduction ? <SalesDemoSwitch name={name} /> : name}
      </Body2>
      <div className="mt-5 flex flex-1 flex-col">
        <nav className="flex-1 space-y-1 pb-4 sm:px-1 lg:px-2">
          {navigation.map((item) => (
            <NavMenuItem key={item.name} item={item} />
          ))}
        </nav>
      </div>
      <div className="mb-10 sm:px-1 lg:px-2">
        <LogoutButton
          item={{
            id: "logout",
            name: t("common:general.signOut"),
            icon: LogoutIcon,
            onClick: signOut,
          }}
        />
      </div>
    </div>
  );
};

export default Sidebar;
