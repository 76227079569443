import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { UseQueryOptions } from "react-query";

interface GetInvestmentModelsProps {
  limit?: number;
  offset?: number;
  sort?: string;
}

const getInvestmentModels = async ({
  limit = 100,
  offset = 0,
  sort,
}: GetInvestmentModelsProps = {}) => {
  const { data } = await openapi.get("/api/v1/admin/security-groups/", {
    params: {
      query: {
        limit,
        offset,
        sort,
      },
    },
  });
  return data;
};

export const useInvestmentModels = (
  props?: GetInvestmentModelsProps,
  options?: UseQueryOptions
) => {
  return useQuery(
    ["investment-models", props],
    () => getInvestmentModels(props || {}),
    options
  );
};

export const getInvestmentModelByUID = async (securityGroupUID: string) => {
  const { data } = await openapi.get(
    `/api/v1/admin/security-groups/{security_group_uid}`,
    {
      params: { path: { security_group_uid: securityGroupUID } },
    }
  );
  return data;
};

const getInvestmentModelByUIDQuery = ({
  securityGroupUid,
}: {
  securityGroupUid: string;
}) => ({
  queryKey: ["investment-model", securityGroupUid],
  queryFn: () => getInvestmentModelByUID(securityGroupUid),
});

export const useInvestmentModelById = (
  securityGroupUid: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getInvestmentModelByUID>>,
    unknown,
    Awaited<ReturnType<typeof getInvestmentModelByUID>>,
    (string | number)[]
  >
) => {
  return useQuery({
    ...getInvestmentModelByUIDQuery({ securityGroupUid }),
    ...options,
  });
};

export const getInvestmentModelByIdWithPerformance = async (securityGroupUid: string) => {
  const { data } = await openapi.get(
    `/api/v1/admin/security-groups/{security_group_uid}/with-meta`,
    {
      params: { path: { security_group_uid: securityGroupUid } },
    }
  );
  return data;
};

export const useInvestmentModelByIdWithPerformance = (
  securityGroupUid: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getInvestmentModelByIdWithPerformance>>
  >
) => {
  return useQuery(
    ["investment-model", securityGroupUid, "withPerformance"],
    () => getInvestmentModelByIdWithPerformance(securityGroupUid),
    options
  );
};

export const investmentModelLoader = (queryClient: QueryClient) => {
  return async ({
    params: { securityGroupUid },
  }: {
    params: { securityGroupUid: string };
  }) => {
    const query = getInvestmentModelByUIDQuery({ securityGroupUid });
    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};
