import { Amplify, Auth } from "aws-amplify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { Error, Loading } from "./components";
import { useAuth } from "./hooks/index";
import { validateCurrentLoginSession } from "./hooks/use-auth/helpers";
import { currentUserSelector } from "./redux/selectors";
import { getAdminRoutes } from "./routes/admin";
import { getAppRoutes } from "./routes/app";
import { getPublicRoutes } from "./routes/public";
import { getActiveTheme, isDevEnvironment } from "./utils/index";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import DefaultLayout from "./layouts/default-layout";
import { getCognitoConfig } from "./config/aws-cognito-config";
import useCompany from "./hooks/dashboards/queries/use-company";
import ErrorBoundary from "./routes/error-boundary";
import { EMAILS_PREVIEWER } from "./views/public/emails-previewer";
import { TailwindCSSProvider } from "./components/tailwind-css-provider";
import { useSchema } from "./hooks/dashboards/queries/use-schema-2";
import { useMeta } from "./hooks/shared/queries/use-meta";

const subdomain = window.location.host.split(".")[0];
Amplify.configure(getCognitoConfig(subdomain));

function App() {
  const { t } = useTranslation();
  const isDevEnv = isDevEnvironment();
  const queryClient = useQueryClient();

  const getRouter = () => {
    const routes = getPublicRoutes({ t });
    if (isDevEnv) {
      return routes
        .concat(getAdminRoutes({ t, queryClient }))
        .concat(getAppRoutes({ subdomain, t }));
    }
    switch (subdomain) {
      case "navia-admin":
      case "admin": {
        return routes.concat(getAdminRoutes({ t, queryClient }));
      }
      default:
        return routes.concat(getAppRoutes({ subdomain, t }));
    }
  };

  let router = createBrowserRouter([
    {
      element: <AppWrapper />,
      errorElement: <ErrorBoundary />,
      children: getRouter().concat([
        {
          path: "/emails/:id",
          element: (
            <TailwindCSSProvider>
              <DefaultLayout showProgressCircle={false}>
                <EMAILS_PREVIEWER />
              </DefaultLayout>
            </TailwindCSSProvider>
          ),
        },
      ]),
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<Loading fullScreen />} />;
}

const AppWrapper = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);
  const { isFetching: isFetchingUser } = useAuth();
  const { data: company } = useCompany(user.company_id, true);
  const { isLoading: isLoadingSchemas } = useSchema();

  const { isLoading: isMetaLoading } = useMeta();

  useEffect(() => {
    const activeTheme = getActiveTheme({ company, user });
    const body = document.getElementById("root-body");
    body.className = activeTheme.cssClass;
  }, [company, user]);

  useEffect(() => {
    validateCurrentLoginSession(user, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, user.id]);

  if (
    isMetaLoading ||
    isFetchingUser ||
    isLoadingSchemas ||
    (subdomain === "navia-app" && !user.id)
  ) {
    return <Loading fullScreen neutralColor />;
  }

  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

export default App;
