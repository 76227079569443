import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import DefaultLayout from "~/layouts/default-layout";
import { currentUserSelector } from "~/redux/selectors";
import { usePageTitle } from "./use-page-title";
import { useTitle } from "./use-title";
import { TailwindCSSProvider } from "~/components/tailwind-css-provider";

const CompanyGuard = () => {
  const { title } = usePageTitle();
  useTitle(title);
  const user = useSelector(currentUserSelector);
  if (user.id || user.authState === "signedin") {
    return <Navigate to="/" />;
  }
  return (
    <TailwindCSSProvider>
      <Outlet />
    </TailwindCSSProvider>
  );
};

export const getPublicRoutes = ({ t }) => [
  {
    element: <CompanyGuard />,
    children: [
      {
        element: <DefaultLayout isRouter={true} showProgressCircle={false} />,
        children: [
          {
            path: "/login",
            handle: { title: t("common:metaTitles.employer.login") },
            async lazy() {
              let { SignInForm } = await import("~/components/authentication");
              return { Component: SignInForm };
            },
            children: [
              {
                index: true,
                async lazy() {
                  let { CredentialsForm } = await import("~/components/authentication");
                  return { Component: CredentialsForm };
                },
              },
              {
                path: "invite-code",
                async lazy() {
                  let { InvitationCodeForm } = await import(
                    "~/components/authentication"
                  );
                  return { Component: InvitationCodeForm };
                },
              },
              {
                path: "confirm-user",
                async lazy() {
                  let { ConfirmationCodeForm } = await import(
                    "~/components/authentication"
                  );
                  return { Component: ConfirmationCodeForm };
                },
              },
              {
                path: "phone-number",
                async lazy() {
                  let { PhoneNumberForm } = await import("~/components/authentication");
                  return { Component: PhoneNumberForm };
                },
              },
              {
                path: "verify-code",
                async lazy() {
                  let { VerificationCodeForm } = await import(
                    "~/components/authentication"
                  );
                  return { Component: VerificationCodeForm };
                },
              },
              {
                path: "mfa-setup",
                async lazy() {
                  let { MFASetupForm } = await import("~/components/authentication");
                  return { Component: MFASetupForm };
                },
              },
            ],
          },
          {
            path: "/sign-up",
            async lazy() {
              let { SignUpForm } = await import("~/components/authentication");
              return { Component: SignUpForm };
            },
            children: [
              {
                index: true,
                element: <Navigate to="invite-code" />,
              },
              {
                path: "invite-code",
                handle: { title: t("common:metaTitles.employer.inviteCode") },
                async lazy() {
                  let { InvitationCodeForm } = await import(
                    "~/components/authentication"
                  );
                  return { Component: InvitationCodeForm };
                },
              },
              {
                path: "account-info",
                handle: { title: t("common:metaTitles.employer.accountInfo") },
                async lazy() {
                  let { AccountInfoForm } = await import("~/components/authentication");
                  return { Component: AccountInfoForm };
                },
              },
              {
                path: "confirm-code",
                handle: { title: t("common:metaTitles.employer.confirmCode") },
                async lazy() {
                  let { ConfirmationCodeForm } = await import(
                    "~/components/authentication"
                  );
                  return { Component: ConfirmationCodeForm };
                },
              },
              {
                path: "phone-number",
                async lazy() {
                  let { PhoneNumberForm } = await import("~/components/authentication");
                  return { Component: PhoneNumberForm };
                },
              },
              {
                path: "mfa-setup",
                handle: { title: t("common:metaTitles.employer.confirmCode") },
                async lazy() {
                  let { MFASetupForm } = await import("~/components/authentication");
                  return { Component: MFASetupForm };
                },
              },
              {
                path: "success",
                async lazy() {
                  let { SuccessPage } = await import("~/components/authentication");
                  return { Component: SuccessPage };
                },
              },
            ],
          },
          {
            path: "/reset-password",
            handle: { title: t("common:metaTitles.employer.resetPassword") },

            async lazy() {
              let { ResetPasswordForm } = await import("~/components/authentication");
              return { Component: ResetPasswordForm };
            },
            children: [
              {
                index: true,
                async lazy() {
                  let { UsernameForm } = await import("~/components/authentication");
                  return { Component: UsernameForm };
                },
              },
              {
                path: "new-password",
                async lazy() {
                  let { NewPasswordForm } = await import("~/components/authentication");
                  return { Component: NewPasswordForm };
                },
              },
              {
                path: "verify-code",
                async lazy() {
                  let { VerificationCodeForm } = await import(
                    "~/components/authentication"
                  );
                  return { Component: VerificationCodeForm };
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
