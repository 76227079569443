import range from "lodash/range";

export const getScheduleTypes = (type, t) => {
  return {
    weekly: t("common:general.payrollTypes.weekly"),
    biweekly: t("common:general.payrollTypes.biweekly"),
    semimonthly: t("common:general.payrollTypes.semimonthly"),
    monthly: t("common:general.payrollTypes.monthly"),
  }[type];
};

export const getPayrollTypes = (t) => [
  {
    label: t("common:general.payrollTypes.weekly"),
    selectedDisplay: t("common:general.payrollTypes.weekly"),
    value: "weekly",
  },
  {
    label: t("common:general.payrollTypes.biweekly"),
    selectedDisplay: t("common:general.payrollTypes.biweekly"),
    value: "biweekly",
  },
  {
    label: t("common:general.payrollTypes.semimonthly"),
    selectedDisplay: t("common:general.payrollTypes.semimonthly"),
    value: "semimonthly",
  },
  {
    label: t("common:general.payrollTypes.monthly"),
    selectedDisplay: t("common:general.payrollTypes.monthly"),
    value: "monthly",
  },
];

export const getScheduleTypeOptions = (t) => ({
  weekly: [
    { label: t("common:general.weekDays.monday"), value: 1 },
    { label: t("common:general.weekDays.tuesday"), value: 2 },
    { label: t("common:general.weekDays.wednesday"), value: 3 },
    { label: t("common:general.weekDays.thursday"), value: 4 },
    { label: t("common:general.weekDays.friday"), value: 5 },
  ],
  biweekly: [
    { label: t("common:general.weekDays.monday"), value: 1 },
    { label: t("common:general.weekDays.tuesday"), value: 2 },
    { label: t("common:general.weekDays.wednesday"), value: 3 },
    { label: t("common:general.weekDays.thursday"), value: 4 },
    { label: t("common:general.weekDays.friday"), value: 5 },
  ],
  monthly: range(1, 31).map((e) => ({ label: e, value: e })),
  semimonthly: [
    { label: t("common:general.semimonthlyOptions.1stAnd15th"), value: 1 },
    {
      label: t("common:general.semimonthlyOptions.15thAndLast"),
      value: 15,
    },
    {
      label: t("common:general.semimonthlyOptions.16thAndLast"),
      value: 16,
    },
  ],
});

/* EMPLOYER DASHBOARD: DOCUMENTS */
export const getSignedContractsData = (t, meta) => [
  {
    id: "serviceLevelAgreement",
    title: t(
      "dashboard:dashboard.employer.documents.signedContracts.serviceLevelAgreement.title"
    ),
    description: t(
      "dashboard:dashboard.employer.documents.signedContracts.serviceLevelAgreement.description",
      {
        providerName: meta.provider_name,
      }
    ),
  },
  {
    id: "adoptionAgreement",
    title: t(
      "dashboard:dashboard.employer.documents.signedContracts.adoptionAgreement.title"
    ),
    description: t(
      "dashboard:dashboard.employer.documents.signedContracts.adoptionAgreement.description"
    ),
  },
  {
    id: "participationAgreement",
    title: t(
      "dashboard:dashboard.employer.documents.signedContracts.participationAgreement.title"
    ),
    description: t(
      "dashboard:dashboard.employer.documents.signedContracts.participationAgreement.description",
      {
        providerName: meta.provider_name,
      }
    ),
  },
  {
    id: "planSummary",
    title: t("dashboard:dashboard.employer.documents.signedContracts.planSummary.title"),
    description: t(
      "dashboard:dashboard.employer.documents.signedContracts.planSummary.description",
      {
        providerName: meta.provider_name,
      }
    ),
    fileName: "documents/planSummary.pdf",
  },
  {
    id: "employerFee",
    title: t("dashboard:dashboard.employer.documents.signedContracts.employerFee.title"),
    description: t(
      "dashboard:dashboard.employer.documents.signedContracts.employerFee.description"
    ),
    fileName: "documents/employerFee.pdf",
  },
  {
    id: "planTrustAgreement",
    title: t(
      "dashboard:dashboard.employer.documents.signedContracts.planTrustAgreement.title"
    ),
    description: t(
      "dashboard:dashboard.employer.documents.signedContracts.planTrustAgreement.description"
    ),
    fileName: "documents/planTrustAgreement.pdf",
  },
  {
    id: "penelopeServiceFee",
    title: t(
      "dashboard:dashboard.employer.documents.signedContracts.penelopeServiceFee.title",
      {
        providerName: meta.provider_name,
      }
    ),
    description: t(
      "dashboard:dashboard.employer.documents.signedContracts.penelopeServiceFee.description",
      {
        providerName: meta.provider_name,
      }
    ),
    fileName: "documents/penelopeServiceFee.pdf",
  },
];

export const RETIREMENT_TYPES = [
  { label: "Traditional 401(k)", value: 1 },
  { label: "Safe Harbor 401(k)", value: 2 },
  { label: "Roth 401(k)", value: 3 },
  { label: "Solo 401(k)", value: 4 },
  { label: "Traditional IRA", value: 5 },
  { label: "SEP", value: 6 },
  { label: "Simple IRA", value: 7 },
];

export const YES_NO = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const HAS_OTHER_ENTITIES = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "I don’t know", value: "idk" },
];

export const COMPANY_TYPES = [
  { label: "Sole Proprietorship", value: "Sole Proprietorship" },
  { label: "Partnership", value: "Partnership" },
  { label: "Corporation", value: "Corporation" },
  { label: "Nonprofit", value: "Nonprofit" },
  { label: "S Corporation", value: "S Corporation" },
  {
    label: "Limited Liability Company (LLC)",
    value: "Limited Liability Company (LLC)",
  },
  { label: "International Business", value: "International Business" },
  { label: "Other", value: "Other" },
];

export const IRS_RETIREMENT_PLANS_STARTUP_COSTS_URL =
  "https://www.irs.gov/retirement-plans/retirement-plans-startup-costs-tax-credit";

export const getNotificationsData = (t, planType, meta) => [
  {
    title: t("dashboard:dashboard.employer.documents.notifications.planDetails.title"),
    description: t(
      "dashboard:dashboard.employer.documents.notifications.planDetails.description"
    ),
    fileName: `documents/${planType}/plan_details.pdf`,
  },
  {
    title: t(
      "dashboard:dashboard.employer.documents.notifications.defaultInvestment.title"
    ),
    description: t(
      "dashboard:dashboard.employer.documents.notifications.defaultInvestment.description"
    ),
    fileName: `documents/${planType}/default_investment.pdf`,
  },
  {
    title: t("dashboard:dashboard.employer.documents.notifications.participantFee.title"),
    description: t(
      "dashboard:dashboard.employer.documents.notifications.participantFee.description"
    ),
    fileName: `documents/${planType}/participant_fee.pdf`,
  },
  {
    title: t(
      "dashboard:dashboard.employer.documents.notifications.penelopeSummaryPlan.title",
      {
        providerName: meta.provider_name,
      }
    ),
    description: t(
      "dashboard:dashboard.employer.documents.notifications.penelopeSummaryPlan.description"
    ),
    fileName: `documents/${planType}/summary_plan_description.pdf`,
  },
  {
    title: t(
      "dashboard:dashboard.employer.documents.notifications.penelopeDefinedContribution.title",
      {
        providerName: meta.provider_name,
      }
    ),
    description: t(
      "dashboard:dashboard.employer.documents.notifications.penelopeDefinedContribution.description"
    ),
    fileName: `documents/${planType}/defined_contribution_plan.pdf`,
  },
];
