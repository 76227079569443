import { createTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ConfirmProvider } from "~/components/super-admin/mui-joy-confirm";
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Outlet, Navigate } from "react-router-dom";
import { Error, Loading } from "~/components";
import { Layout } from "~/components/super-admin/layout";
import ModalContextProvider from "~/components/super-admin/modals/modal-context";
import "~/components/super-admin/styles.css";
import { currentUserSelector, errorSelector } from "~/redux/selectors";
import { usePageTitle } from "~/routes/use-page-title";
import DefaultLayout from "~/layouts/default-layout";

import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import {
  extendTheme as joyExtendTheme,
  CssVarsProvider as JoyCssVarsProvider,
} from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import { useOriginalUrl } from "~/routes/use-original-url";
import { useTranslation } from "react-i18next";
import { useSchema } from "~/hooks/dashboards/queries/use-schema-2";
import { AdminCustomToaster } from "~/components/super-admin/custom-toaster";
import { useIsProduction, useMeta } from "~/hooks/shared/queries/use-meta";

const themesMap = {
  "dev-theme": "danger",
  "production-theme": "neutral",
};

const getThemes = (isProduction, meta) => {
  let theme = createTheme({
    palette: {
      primary: {
        main: isProduction ? "#163B16" : "#997F37",
      },
      secondary: {
        main: "#141A16",
      },
      danger: {
        main: "#f44336",
      },
      info: {
        main: "#0288d1",
      },
    },
  });

  theme = createTheme(theme, {
    palette: {
      pending: theme.palette.augmentColor({ color: { main: "#808080" } }),
      progressing: theme.palette.augmentColor({ color: { main: "#1976d2" } }),
      approved: theme.palette.augmentColor({ color: { main: "#4caf50" } }),
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-actionsCell": {
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              minWidth: "fit-content",
              gap: "1rem",
              "& *": {
                flexShrink: 0,
              },
            },
          },
        },
      },
    },
  });

  // This is a stopgap while Joy UI and MUI are both being used
  // @see https://mui.com/joy-ui/integrations/material-ui/
  const materialTheme = materialExtendTheme(theme);
  const joyTheme = joyExtendTheme({
    components: {
      JoyStack: {
        defaultProps: {
          useFlexGap: true,
        },
      },
    },
    sidebar: {
      variant: !isProduction && "soft",
      color: themesMap[meta.tpa_theme],

      width: {
        open: 240,
        closed: 60,
        closedXs: 50,
      },
    },
  });

  return { materialTheme, joyTheme };
};

const ChildrenWrapper = ({ children }) => {
  const { isLoading: isLoadingSchemas } = useSchema();
  if (isLoadingSchemas) {
    return <Loading fullScreen />;
  }
  return children;
};

function SuperAdminDashboard() {
  const { title } = usePageTitle();
  const { i18n } = useTranslation();

  const user = useSelector(currentUserSelector);
  const error = useSelector(errorSelector);
  const { url: loginUrlWithOriginalUrl } = useOriginalUrl({ pathname: "/login" });
  const isProduction = useIsProduction();
  const { data: meta } = useMeta();
  useEffect(() => {
    i18n?.loadNamespaces(["admin"]);
  }, [i18n]);

  if (!isEmpty(error)) {
    return (
      <DefaultLayout showProgressCircle={false}>
        <Error
          code={error.code}
          message={error.message}
          description={error.description}
          canGoHome={false}
        />
      </DefaultLayout>
    );
  }

  if (!user.id) {
    return <Navigate to={loginUrlWithOriginalUrl} />;
  }
  if (!user.is_superuser && ["admin", "owner"].includes(user.role)) {
    return <Navigate to="/company/main" />;
  }
  if (!user.is_superuser && ["user"].includes(user.role)) {
    return <Navigate to="/individual/main" />;
  }
  const { materialTheme, joyTheme } = getThemes(isProduction, meta);
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider theme={joyTheme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ConfirmProvider>
            <ModalContextProvider>
              <Layout title={title}>
                <AdminCustomToaster />
                <ChildrenWrapper>
                  <Suspense fallback={<Loading fullScreen />}>
                    <Outlet />
                  </Suspense>
                </ChildrenWrapper>
              </Layout>
            </ModalContextProvider>
          </ConfirmProvider>
        </LocalizationProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default SuperAdminDashboard;
