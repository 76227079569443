import { useQueries, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";

export const companyQueryKey = (companyId: number | string) => ["companies", companyId];

const fetchCompany = async (companyId: number | string) => {
  const { data } = await openapi.get("/api/v1/companies/{company_id}", {
    params: {
      path: {
        company_id: Number(companyId),
      },
    },
  });

  return data;
};

export const useCompany = (companyId: number | string, suspense: boolean = false) => {
  return useQuery({
    queryKey: companyQueryKey(companyId),
    queryFn: () => fetchCompany(companyId),
    enabled: !!companyId,
    useErrorBoundary: true,
    suspense,
  });
};

export const useCompaniesByIds = (ids: string[]) => {
  const companyQueries = useQueries(
    (ids || []).map((id) => ({
      queryKey: ["company", id],
      queryFn: () => fetchCompany(id),
      enabled: !!id,
      staleTime: 60 * 60 * 1000,
    }))
  );

  const companies = companyQueries.map((query) => query.data).filter(Boolean);
  const isLoading = companyQueries.some((query) => query.isLoading);

  return { companies, isLoading };
};

export default useCompany;
