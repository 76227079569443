// @ts-ignore
import tailwindConfig from "tailwind.config.js";
import { extendTailwindMerge } from "tailwind-merge";

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        "xs",
        "sm",
        "base",
        "lg",
        "xl",
        "2xl",
        "3xl",
        "4xl",
        "5xl",
        "6xl",
        "7xl",
        "8xl",
        "9xl",
      ]
        .concat(Object.keys(tailwindConfig.theme.extend.fontSize))
        .map((cls) => `text-${cls}`),
    },
  },
});

export default twMerge;
