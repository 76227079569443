import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiFetchOptions } from "~/types/openapi-params";

interface FetchSecurityFeeProps {
  securityId: string;
  feeId: string;
  fetchOptions?: OpenApiFetchOptions<
    "get",
    "/api/v1/admin/securities/{security_id}/fees/{fee_id}"
  >;
}

export const fetchSecurityFee = async ({
  securityId,
  feeId,
  fetchOptions = {},
}: FetchSecurityFeeProps) => {
  const { data } = await openapi.get("/api/v1/admin/securities/{security_id}/fees/{fee_id}", {
    ...fetchOptions,
    params: {
      path: {
        security_id: Number(securityId),
        fee_id: Number(feeId),
      },
    },
  });
  return data;
};

interface SecurityFeeQueryProps {
  securityId: string;
  feeId: string;
}

const securityFeeQuery = ({ securityId, feeId }: SecurityFeeQueryProps) => ({
  queryKey: ["securityFees", { securityId, feeId }],
  queryFn: () => fetchSecurityFee({ securityId, feeId }),
  enabled: !!securityId && !!feeId,
});

export const useSecurityFee = ({ securityId, feeId }: SecurityFeeQueryProps) => {
  return useQuery(
    securityFeeQuery({
      securityId,
      feeId,
    })
  );
};

export const securityFeeLoader = (queryClient: QueryClient) => {
  return async ({ params }) => {
    const query = securityFeeQuery({
      securityId: params.id,
      feeId: params.fee_id,
    });

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};
