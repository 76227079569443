import { toTitleCase } from ".";

export const hasSafeHarborMatch = (config) => {
  return config.allowed_contribution_types.includes("employer_safe_harbor_match");
};
export const hasLoans = (config) => {
  return config.allowed_contribution_types.includes("loan_payment");
};

export const maxContributionPercentage = (config) => {
  return config.max_participant_percentage;
};

export const getVestingMonths = (config) => {
  const { vesting_length_of_service } = config;
  return vesting_length_of_service;
};

export const hasVesting = (config) => {
  return getVestingMonths(config) > 0;
};
export const hasPartTime = (config) => {
  const { part_time_hours } = config;
  return part_time_hours > 0;
};

export const getExcludedEmployeeTypes = (config) => {
  const { excludable_class } = config;
  return excludable_class;
};

export const getPlanName = (company) => {
  return toTitleCase(company.retirement_plan.custodian_plan_id);
};
