import { Fragment, useRef, useState } from "react";
// TODO: replace this with svg icon to remove the extra lib
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Combobox, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import isNil from "lodash/isNil";
import { Body2 } from "../typography";
import ErrorMessage from "./error-message";
import twMerge from "~/utils/tw-merge";
import { Label } from "../ui/label";
function Dropdown({
  error,
  label,
  options = [],
  value = null,
  setValue,
  disabled = false,
  className = "",
  placeholder = "",
  description,
  id,
  required = false,
}) {
  const [query, setQuery] = useState("");
  const buttonRef = useRef(null);
  // if the value is not in the options fallback to show that value
  const fallbackValue = { label: value, value: value };
  const placeholderObj = { label: placeholder };
  const selectValue =
    options.find((option) => option.value === value) ||
    (value ? fallbackValue : placeholderObj);

  const errorClass = error ? "penelope-input-error" : "";

  const filteredOptions = options.filter((option) =>
    option.label
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  const onChange = (item) => {
    setValue(item.value);
  };

  return (
    <div className={twMerge("w-full", className, errorClass)} id={id}>
      <Combobox value={selectValue} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <>
            {!isNil(label) && (
              <Label
                required={required}
                className={`mb-2 ${disabled ? "text-text-secondary-light_1" : ""}`}
              >
                {label}
              </Label>
            )}
            <div className="relative mt-1">
              <div
                className={twMerge(
                  "relative h-12 w-full cursor-default overflow-hidden rounded border border-solid border-transparent bg-secondary-light_3 text-left text-sm focus:outline-none",
                  open && isNil(label) && "border-pen-carolina-04",
                  !isNil(error) && "border-error-main focus:border-error-main"
                )}
              >
                <Combobox.Input
                  className={twMerge(
                    "w-full border-none bg-secondary-light_2 p-4 pr-10 text-sm font-normal text-text-secondary-main focus:ring-0",
                    disabled && "text-text-secondary-light_1",
                    !isNil(error) && "text-error-main"
                  )}
                  displayValue={(option) => option.label}
                  onChange={(event) => setQuery(event.target.value)}
                  onClick={() => buttonRef.current.click()}
                  autoComplete="off"
                />
                <Combobox.Button
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  ref={buttonRef}
                >
                  <ChevronDownIcon
                    className="mt-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options className="absolute z-10 mt-1 max-h-[14.25rem] w-full overflow-auto rounded border-b border-solid border-secondary-light_1 bg-secondary-light_2 py-1 text-sm text-text-secondary-main focus:outline-none">
                  {filteredOptions.length === 0 && query !== "" ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-text-secondary-main">
                      Nothing found.
                    </div>
                  ) : (
                    filteredOptions.map((option) => (
                      <Combobox.Option
                        key={option.label}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? "bg-secondary-light_1" : ""
                          }`
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {option.label}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-text-secondary-main" : ""
                                }`}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </>
        )}
      </Combobox>
      {!isNil(description) && (
        <Body2
          className={twMerge(
            "mt-1 text-text-secondary-light_1",
            disabled && "text-text-secondary-light_1"
          )}
        >
          {description}
        </Body2>
      )}
      <ErrorMessage className="mt-1" error={error} />
    </div>
  );
}

export default Dropdown;

Dropdown.propTypes = {
  error: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
};
