import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Auth, Hub } from "aws-amplify";
import { getCurrentUser } from "~/services/api";
import { updateCurrentUser } from "~/redux/current-user-slice";
import { authState } from "~/constants";
import { currentUserSelector } from "~/redux/selectors";
import { getAuthObject, handleAuthError, handleSignIn, listener } from "./helpers";
import { AxiosError } from "axios";

export const meQueryKey = ["users", "me"];

// this hook is meant to be used for one time in App.js
// if you need the user obj, you can get it from the store
function useAuth() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user: { [x: string]: any } = useSelector(currentUserSelector);
  const [isFetchingState, setIsFetchingState] = useState(true);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const originalUrl = searchParams.get("from");

  const { isError: isErrorFetchingUser } = useQuery({
    queryKey: meQueryKey,
    queryFn: getCurrentUser,
    enabled: "authState" in user && user.authState === authState.SignedIn,
    onSuccess: (payload) => handleSignIn(payload, dispatch, t),
    onError: (err: AxiosError) => handleAuthError(err, dispatch, t, user),
    suspense: true,
    useErrorBoundary: false
  });

  const checkIfUserLoggedIn = useCallback(async () => {
    try {
      const userObj = await Auth.currentAuthenticatedUser();
      dispatch(updateCurrentUser(getAuthObject(userObj)));
    } catch (err) {
      setIsFetchingState(false);
    }
  }, [dispatch]);

  useEffect(() => {
    checkIfUserLoggedIn();
    const unlisten = Hub.listen("auth", ({ payload }) =>
      listener({
        payload,
        setIsFetchingState,
        queryClient,
        dispatch,
        originalUrl,
        navigate,
      })
    );
    return unlisten;
  }, [checkIfUserLoggedIn, dispatch, navigate, originalUrl, queryClient]);

  useEffect(() => {
    if (user.id || isErrorFetchingUser) {
      setIsFetchingState(false);
    }
  }, [user, isErrorFetchingUser]);

  return {
    isFetching: isFetchingState,
  };
}

export default useAuth;
