import ceil from "lodash/ceil";
import first from "lodash/first";
import last from "lodash/last";
import sortBy from "lodash/sortBy";
import enUS from "date-fns/locale/en-US";
import { formatInTimeZone } from "date-fns-tz";
import { AVAILABLE_THEMES, FUN_VIDEO } from "../constants";
import { formatDistanceStrict, parseISO, isThisYear } from "date-fns";
import { sentenceCase } from "change-case";
import format from "date-fns/format";
import { noCase } from "change-case";

export const MONTH_DAY = "MM/dd";
export const MONTH_DAY_YEAR = "MM/dd/yyyy";
export const YEAR_MONTH_DAY = "yyyy-MM-dd";

const formatDistanceLocale = {
  lessThanXSeconds: "{{count}} seconds",
  xSeconds: "{{count}} seconds",
  halfAMinute: "30 seconds",
  lessThanXMinutes: "{{count}} minutes",
  xMinutes: "{{count}} minutes",
  aboutXHours: "{{count}} hours",
  xHours: "{{count}} hours",
  xDays: "{{count}} days",
  aboutXWeeks: "{{count}} weeks",
  xWeeks: "{{count}} weeks",
  aboutXMonths: "{{count}} months",
  xMonths: "{{count}} months",
  aboutXYears: "{{count}} years",
  xYears: "{{count}} years",
  overXYears: "{{count}} years",
  almostXYears: "{{count}} years",
};

const dateLocale = {
  ...enUS,
  formatDistance: (token, count, options) => {
    options = options || {};

    if (token === "xDays" && count >= 7) {
      count = Math.floor(count / 7);
      token = "xWeeks";
    }

    let result = formatDistanceLocale[token].replace("{{count}}", count);

    if (count === 1) result = result.replace(/s$/, "");

    if (options.addSuffix) {
      if (options.comparison > 0) {
        return "in " + result;
      } else {
        return result + " ago";
      }
    }

    return result;
  },
};

export const isNil = (val) => val == null;

const getValue = (val) => (!isNil(val) ? val : "");

export const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US").format(number);
};

export function deleteKeysFromObject(obj, keys) {
  const newObj = { ...obj };
  keys.forEach((key) => {
    if (newObj.hasOwnProperty(key)) {
      delete newObj[key];
    }
  });
  return newObj;
}

export function transformArrayToObject(array, key) {
  return array.reduce((acc, current) => {
    acc[current[key]] = {
      ...current,
    };
    return acc;
  }, {});
}

export function range(n) {
  return Array.from({ length: n + 1 }, (_, i) => i);
}

export function isPlainObject(value) {
  return (
    value !== null &&
    typeof value === "object" &&
    !Array.isArray(value) &&
    Object.prototype.toString.call(value) === "[object Object]"
  );
}

export function prefixArrItems(arr, prefix = "") {
  return arr.map((item) => `${prefix}${item}`);
}

export function prefixKeys(obj, keyMap, prefix, remove = false) {
  Object.keys(obj).forEach((key) => {
    if (keyMap.includes(key)) {
      const newKey = `${prefix}${key}`;
      obj[newKey] = obj[key];
      if (remove) {
        delete obj[key];
      }
    }
  });
  return obj;
}

export const parseName = (fullName) => {
  const nameParts = fullName.trim().split(" ");
  const firstName = nameParts.slice(0, -1).join(" ");
  const lastName = nameParts[nameParts.length - 1];
  return { firstName, lastName };
};

export const formatFullName = ({ firstName, lastName, middleName = "" }) => {
  const nameParts = [firstName, middleName, lastName].filter(Boolean);
  return nameParts.join(" ");
};

export const formatLegalName = ({ first_name, last_name } = {}) => {
  return `${first_name || ""} ${last_name || ""}`;
};

export const formatAddress = (addressData) => {
  if (isNil(addressData)) {
    return;
  }
  const { address, address_1, address_2, state, city, zip, apartment } = addressData;

  const parts = [];
  if (apartment) parts.push(apartment);
  if (address) parts.push(address);
  if (address_1) parts.push(address_1);
  if (address_2) parts.push(address_2);

  const cityStateZip = [city, state, zip].filter((val) => !isNil(val)).join(" ");
  if (cityStateZip) parts.push(cityStateZip);
  if (parts.length === 0) return null;

  return parts.join("\n");
};

export const formatDate = (date, dateFormat = MONTH_DAY_YEAR) => {
  if (!date) return null;
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return format(date, dateFormat);
};

export const formatApiDate = (date) => {
  return formatDate(date, YEAR_MONTH_DAY);
};

export const formatHumanDateTime = (dateString, showYear = false) => {
  if (!dateString) return;
  if (!(dateString instanceof Date)) {
    dateString = parseISO(dateString);
  }

  const date = new Date(dateString);
  const dateFormat =
    !showYear && isThisYear(date) ? "MMM d, hh:mm aa" : "MMM d yyyy, hh:mm aa";

  return formatInTimeZone(
    new Date(dateString).toISOString(),
    "America/New_York",
    dateFormat,
    { locale: dateLocale }
  );
};

export const formatHumanTime = (dateString) => {
  if (!dateString) return;
  if (!(dateString instanceof Date)) {
    dateString = parseISO(dateString);
  }

  const dateFormat = "hh:mm aa";

  return formatInTimeZone(
    new Date(dateString).toISOString(),
    "America/New_York",
    dateFormat,
    { locale: dateLocale }
  );
};

export const formatHumanDate = (dateString, showYear = false) => {
  if (!dateString) return;
  if (!(dateString instanceof Date)) {
    dateString = parseISO(dateString);
  }

  const date = new Date(dateString);
  const dateFormat = !showYear && isThisYear(date) ? "MMM d" : "MMM d yyyy";

  return format(date, dateFormat);
};

export const formatDateTimeET = (dateString, showTimezone = true) => {
  if (!dateString) return;
  // TODO: Confirm with Alissa and Anies
  if (!(dateString instanceof Date)) {
    dateString = parseISO(dateString);
  }
  return formatInTimeZone(
    new Date(dateString).toISOString(),
    "America/New_York",
    `MM/dd/yyyy HH:mm${showTimezone ? " zzz" : ""}`,
    { locale: dateLocale }
  );
};

export const formatDateET = (dateString, showTimezone = true) => {
  if (!dateString) return;
  // TODO: Confirm with Alissa and Anies
  if (!(dateString instanceof Date)) {
    dateString = parseISO(dateString);
  }
  return formatInTimeZone(
    new Date(dateString).toISOString(),
    "America/New_York",
    `MM/dd/yyyy${showTimezone ? " zzz" : ""}`,
    { locale: dateLocale }
  );
};

export const formatTimeET = (dateString, showTimezone = true) => {
  if (!dateString) return;
  // TODO: Confirm with Alissa and Anies
  if (!(dateString instanceof Date)) {
    dateString = parseISO(dateString);
  }
  return formatInTimeZone(
    new Date(dateString).toISOString(),
    "America/New_York",
    `HH:mm${showTimezone ? " zzz" : ""}`,
    { locale: dateLocale }
  );
};

export const formatDateDistance = (date, referenceDate = new Date()) => {
  if (!date) return null;
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }

  return formatDistanceStrict(date, referenceDate, {
    locale: dateLocale,
    includeSeconds: false,
    addSuffix: true,
  });
};

export const formatCurrency = ({
  amount,
  currency_code = "USD",
  digits = 2,
  includeCurrency = true,
}) => {
  if (amount === null || amount === undefined) return null;
  const result = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency_code,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(amount);
  if (includeCurrency) {
    return result;
  } else {
    return result.replace("$", "");
  }
};

export const formatUnit = ({ value, unit }) => {
  const unitsMap = {
    month: "mo.",
    day: "day",
    year: "year",
  };
  return `${value} ${unitsMap[unit]}`;
};

export const formatPercentage = (
  number,
  {
    signDisplay = "auto",
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    excludePercentSymbol = false,
  } = {}
) => {
  const option = {
    style: "percent",
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
  };

  if (signDisplay === "auto" && number < 0) {
    option.signDisplay = "exceptZero";
  }
  try {
    const result = new Intl.NumberFormat("en-US", option).format(number || 0);
    if (excludePercentSymbol) {
      return result.replace("%", "");
    } else {
      return result;
    }
  } catch (err) {
    console.error(err);
    const result = `${number * 100}`.split(".");
    const fraction = result.length > 1 ? `.${result[1]}` : "";
    return `${result[0]}${fraction.slice(0, 3)}%`;
  }
};

export function prefixProperties(schema, prefix) {
  const newSchema = JSON.parse(JSON.stringify(schema));
  const prefixedProperties = {};
  for (const key in newSchema.properties) {
    prefixedProperties[`${prefix}${key}`] = newSchema.properties[key];
  }
  newSchema.properties = prefixedProperties;
  if (Array.isArray(newSchema.required)) {
    newSchema.required = newSchema.required.map((field) => `${prefix}${field}`);
  }
  return newSchema;
}

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + "...";
  }
  return text;
};

export const setItem = (name, val) => {
  if (typeof val === "object") {
    localStorage.setItem(name, JSON.stringify(val));
  } else {
    localStorage.setItem(name, val);
  }
};

export const getItem = (name) => {
  const value = localStorage.getItem(name);
  let returnVal;
  try {
    returnVal = JSON.parse(value);
  } catch (err) {
    returnVal = value;
  }
  return returnVal;
};

export const toFixed = (num, digits = 0) => {
  const fact = 10 ** digits;
  const value = parseFloat(num.toPrecision(14));
  return Math.trunc(value * fact) / fact;
};

export function getYearsFromUntilNow(from = 1950) {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = from; year <= currentYear; year++) {
    years.unshift(year);
  }

  return years;
}

export const toTitleCase = (title) => {
  return sentenceCase(noCase(title || ""));
};

export const snakeCaseToSentenceCase = (str) => {
  return str
    ? str
        .split("_")
        .map((word, index) => {
          if (word && index === 0) {
            return word[0].toUpperCase() + word.slice(1).toLowerCase() || "";
          } else if (word) {
            return word.toLowerCase();
          }
          return "";
        })
        .join(" ")
    : str;
};

export const snakeCaseToTitleCase = (str) => {
  return str
    ? str
        .split("_")
        .map(
          (word) => (word && word[0].toUpperCase() + word.slice(1).toLowerCase()) || ""
        )
        .join(" ")
    : str;
};

export const camelCaseToTitleCase = (val) => {
  const result = `${val}`.replace(/([A-Z])/g, " $1");
  const title = result.charAt(0).toUpperCase() + result.slice(1);
  return title;
};

export const isSnakeCase = (val) => `${val}`.includes("_");

export const formatTitle = (title) =>
  isSnakeCase(title) ? snakeCaseToTitleCase(title) : camelCaseToTitleCase(title);

// ToDo: test
export async function copyToClipboard(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}

export const masksMap = {
  ein: (val) => {
    if (isNil(val)) return;
    const cleanVal = val.replace(/[^\d-]/g, "");
    return cleanVal.replace(/(\d{2})(\d{7})/, "$1-$2");
  },
  zip: (val) => {
    if (isNil(val)) return;
    const cleanVal = val.replace(/[^\d]/g, "");
    return cleanVal.replace(/(\d{5})(\d{4})/, "$1-$2");
  },
  ssn: (val) => {
    if (isNil(val)) return;
    const cleanVal = val.replace(/[^\d]/g, "");
    return cleanVal.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
  },
  ssnStars: (val) => {
    if (isNil(val)) return;
    const cleanVal = val.replace(/[^\d*]/g, "");
    return cleanVal.replace(/(\d{3})(\d{2})(\d{4})/, "*****$3");
  },
  tin: (val) => {
    if (isNil(val)) return;
    const cleanVal = val.replace(/[^\d]/g, "");
    return cleanVal.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
  },
};

export const isFieldRequired = (name, schema) =>
  schema.fields[name].exclusiveTests.required;

export const isDevEnvironment = () =>
  ["development", "test"].includes(import.meta.env.VITE_APP_ENV);

export const checkIfNetworkError = (error) =>
  error.message === "Network Error" || error.message === "Failed to fetch";

export const wait = (timeout) =>
  new Promise((res) =>
    setTimeout(() => {
      res();
    }, timeout)
  );

export const genQRcode = (name, code, meta) =>
  `otpauth://totp/${meta.provider_name}:${name}?secret=${code}&issuer=${meta.provider_name}`;

export const getYTicks = ({ chartData, yAccessorKey = "y" }) => {
  const sortedData = sortBy(chartData, [yAccessorKey]);
  const max = last(sortedData)?.[yAccessorKey];
  const step = max / 2;
  const value = ceil(step);
  return [0];
};

export const getFundYTicks = (chartData) => {
  const sortedData = sortBy(chartData, ["y"]);
  const min = Math.floor(first(sortedData)?.y);
  const max = last(sortedData)?.y;
  const step = (max - min) / 2;
  const value = ceil(step);
  return [min, min + value, min + value * 2];
};

// TODO: refactor this (move to translation & map over them)
export const getPrivacyDisclosures = (t) => {
  return [
    {
      title: null,
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.introduction"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy1.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy1.content"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy2.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy2.content"
      ),
      list: [...Array(9).keys()].map((index) =>
        t(
          `dashboard:dashboard.employer.onboarding.disclosures.privacy.policy2.list.item${index}`
        )
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy3.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy3.content"
      ),
      list: [...Array(8).keys()].map((index) =>
        t(
          `dashboard:dashboard.employer.onboarding.disclosures.privacy.policy3.list.item${index}`
        )
      ),
      content2: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy3.content2"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy4.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy4.content"
      ),
      content2: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy4.content2"
      ),
      content3: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy4.content3"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy5.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy5.content"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy6.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy6.content"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy7.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy7.content"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy8.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy8.content"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy9.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy9.content"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy10.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy10.content"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy11.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy11.content"
      ),
    },
    {
      title: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy12.title"
      ),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.privacy.policy12.content"
      ),
    },
  ];
};

// TODO: refactor this (move to translation & map over them)
export const getTermsDisclosures = (t) => {
  return [
    {
      title: null,
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.introduction.intro1"
      ),
      content2: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.introduction.intro2"
      ),
      content3: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.introduction.intro3"
      ),
      content4: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.introduction.intro4"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term1.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term1.content"
      ),
      content2: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term1.content2"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term2.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term2.content"
      ),
      content2: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term2.content2"
      ),
      content3: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term2.content3"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term3.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term3.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term4.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term4.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term5.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term5.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term6.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term6.content"
      ),
      list: [...Array(6).keys()].map((index) =>
        t(
          `dashboard:dashboard.employer.onboarding.disclosures.terms.term6.list.item${index}`
        )
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term7.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term7.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term8.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term8.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term9.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term9.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term10.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term10.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term11.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term11.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term12.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term12.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term13.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term13.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term14.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term14.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term15.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term15.content"
      ),
    },
    {
      title: t("dashboard:dashboard.employer.onboarding.disclosures.terms.term16.title"),
      content: t(
        "dashboard:dashboard.employer.onboarding.disclosures.terms.term16.content"
      ),
    },
  ];
};

export const showFunVideo = () => {
  window.open(FUN_VIDEO);
};

// get paths of deeply nested props in an object, ex: ["a.b.c.f", "x.y.z"]
export const getPropsPaths = (object) => {
  const getPath = (fields) =>
    Object.entries(fields).reduce((acc, [key, val]) => {
      const path = typeof val === "object" ? getPath(val) : "";
      if (typeof path === "object") {
        Object.keys(path).forEach((k1) => (acc[`${key}.${k1}`] = true));
      } else {
        acc[`${key}${path ? "." : ""}${path}`] = true;
      }
      return acc;
    }, {});
  return Object.keys(getPath(object));
};

export const filterBy = (object, conditionFunc, defaultValue = null) => {
  if (conditionFunc(object)) {
    return object;
  } else {
    return defaultValue;
  }
};

export const transformContributions = (contributions = []) => {
  const result = { total: null };
  if (!contributions.length) return result;
  result.total = 0;
  contributions.forEach(({ amount, contribution_account_type }) => {
    if (isNil(amount)) return;
    if (!result[contribution_account_type]) {
      result[contribution_account_type] = amount;
    } else {
      result[contribution_account_type] += amount;
    }
    result.total += amount;
  });
  return result;
};

export const toggleBodyClasses = (class1, class2) => {
  const body = document.getElementById("root-body");
  body.classList.remove(class1);
  body.classList.add(class2);
};

export const getActiveTheme = ({ company, user }) => {
  let activeTheme = {
    logo: AVAILABLE_THEMES.penelopeEmployer.logo,
    cssClass: AVAILABLE_THEMES.penelopeEmployer.cssClass,
    isCustomLogo: false,
  };
  if (!user.id || !company) {
    return activeTheme;
  }
  const channelSource = company.channel_source ?? "penelope";
  const demo_theme = company.target_data?.custom_theme;
  const selectedTheme = AVAILABLE_THEMES[demo_theme];
  if (company.demo_mode && selectedTheme) {
    const custom_logo_url = company?.target_data?.custom_logo;
    const custom_logo_url_short = company?.target_data?.custom_logo_short;
    const isCustomLogo = custom_logo_url || custom_logo_url_short;
    return {
      logo: selectedTheme.logo,
      cssClass: selectedTheme.cssClass,
      isCustomLogo,
    };
  }
  switch (channelSource) {
    case "navia":
      activeTheme = {
        logo: AVAILABLE_THEMES.navia.logo,
        cssClass: AVAILABLE_THEMES.navia.cssClass,
        isCustomLogo: false,
      };
      break;
    default:
      if (user.role === "user") {
        activeTheme = {
          logo: AVAILABLE_THEMES.penelopeEmployee.logo,
          cssClass: AVAILABLE_THEMES.penelopeEmployee.cssClass,
          isCustomLogo: false,
        };
      }
  }
  return activeTheme;
};
