import { createSelector } from "reselect";
import { components } from "~/types/openapi-schema";

export const adminsSelector = createSelector(
  (state) => state.manageAdmins.admins,
  (data) => data
);

export const currentUserSelector = createSelector(
  (state) => state.currentUser,
  (data) => data as components["schemas"]["UserExtended"] | null
);

export const errorSelector = createSelector(
  (state) => state.error,
  (data) => data
);
