import isNil from "lodash/isNil";
import { useState, Ref } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { Listbox, Transition } from "@headlessui/react";
// TODO: replace this with svg icon to remove the extra lib
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Body2 } from "../typography";
import ErrorMessage from "./error-message";
import twMerge from "~/utils/tw-merge";
import ClearIcon from "~/assets/images/close.svg";
import { Label } from "../ui/label";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

export interface SelectOption {
  label: string | number;
  value: any;
}

interface SelectProps {
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | null;
  label?: string | null;
  variant?: "default" | "sidebar";
  size?: "normal" | "small";
  className?: string;
  options?: SelectOption[];
  value?: any;
  disabled?: boolean;
  setValue?: (value: any) => void;
  placeholder?: string;
  description?: string | null;
  dataCy?: string;
  required?: boolean;
  onClick?: () => void;
  clearable?: boolean;
}

const variants = {
  default: {
    button: "",
    text: "text-text-secondary-main",
    list: "bg-secondary-light_2",
    option: "text-text-secondary-main",
    background: "bg-secondary-light_2",
    selectedOption: "text-text-secondary-light",
    activeOption: "bg-secondary-light_1",
    optionsShadow: "text-text-secondary-light",
  },
  sidebar: {
    button: "pr-4",
    text: "text-navbar-text font-bold",
    list: "bg-secondary-light_2",
    option: "text-text-secondary-light_1",
    background: "bg-transparent",
    selectedOption: "text-text-secondary-main",
    activeOption: "bg-secondary-light_1",
    optionsShadow: "text-text-secondary-light_1",
  },
  // size
  normal: {
    height: "h-12",
    input: "p-4",
  },
  small: {
    height: "h-8",
    input: "p-2 -ml-2",
  },
};

export function Select({
  error = null,
  label = null,
  variant = "default",
  size = "normal",
  className = "",
  options = [],
  value = null,
  disabled = false,
  setValue = null,
  placeholder = "",
  description = null,
  dataCy = "",
  required = false,
  onClick = null,
  clearable = false,
}: SelectProps) {
  let [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  let [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  // if the value is not in the options fallback to show that value
  const defaultValue = options.find((option) => option.value === value) || {
    label: value,
    value: value,
  };
  const errorClass = error ? "penelope-input-error" : "";

  const onChange = (item: SelectOption) => {
    setValue && setValue(item.value);
  };

  return (
    <div className={twMerge("w-full", className, errorClass)} data-cy={dataCy}>
      <Listbox value={defaultValue} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <>
            {!isNil(label) && (
              <div className="mb-2 flex flex-row items-center gap-2">
                <Label
                  required={required}
                  className={`${disabled ? "text-text-secondary-light_1" : ""}`}
                >
                  {label}
                </Label>
              </div>
            )}
            <div className="relative">
              <Listbox.Button
                onClick={() => {
                  onClick && onClick();
                }}
                ref={setReferenceElement as Ref<HTMLButtonElement>}
                className={twMerge(
                  "relative w-full cursor-default rounded border border-solid border-transparent pr-10 text-left focus:outline-none",
                  variants[variant].text,
                  variants[variant].background,
                  variants[size].height,
                  variants[size].input,
                  open && isNil(label) && "border-pen-carolina-04",
                  !isNil(error) && "border-error-main focus:border-error-main",
                  disabled && "cursor-not-allowed opacity-70"
                )}
              >
                <Body2
                  className={twMerge(
                    "truncate leading-3.5",
                    !isNil(error) && "text-error-main",
                    variants[variant].text,
                    variants[variant].button,
                    disabled && "text-text-secondary-light_1"
                  )}
                >
                  {defaultValue.label ?? placeholder}
                </Body2>

                {clearable && !isNil(value) && (
                  <img
                    src={ClearIcon}
                    alt="Clear"
                    className="absolute right-9 top-1/2 mb-2 h-4 w-4 -translate-y-1/2 cursor-pointer rounded-full border bg-secondary-light_2 p-0.5"
                    onClick={(e) => {
                      e.preventDefault();
                      setValue && setValue(null);
                    }}
                  />
                )}

                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                as="div"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {createPortal(
                  <Listbox.Options
                    ref={setPopperElement as Ref<HTMLElement>}
                    className={twMerge(
                      "z-[100] max-h-[14.25rem] overflow-auto rounded border-b border-solid border-secondary-light_1 py-1 text-base focus:outline-none sm:text-sm",
                      variants[variant].list,
                      variants[variant].text
                    )}
                    style={{
                      ...styles.popper,
                      width: referenceElement?.getBoundingClientRect().width,
                    }}
                    {...attributes.popper}
                  >
                    {options.map((option, optionIdx) => (
                      <Listbox.Option
                        key={optionIdx}
                        className={({ active }) =>
                          twMerge(
                            "relative cursor-default select-none py-2 pl-10 pr-4 ",
                            active && variants[variant].activeOption
                          )
                        }
                        value={option}
                      >
                        {({ selected }) => (
                          <>
                            <Body2
                              className={twMerge(
                                "block truncate",
                                selected && "font-medium",
                                variants[variant].text,
                                selected && variants[variant].selectedOption,
                                variants[variant].option
                              )}
                            >
                              {option.label}
                            </Body2>
                            {selected ? (
                              <span
                                className={twMerge(
                                  "absolute inset-y-0 left-0 flex items-center pl-3",
                                  variants[variant].selectedOption
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>,
                  document.body
                )}
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {!isNil(description) && (
        <Body2
          className={twMerge(
            "mt-1 text-text-secondary-light_1",
            disabled && "text-text-secondary-light_1"
          )}
        >
          {description}
        </Body2>
      )}
      <ErrorMessage className="mt-1" error={error} />
    </div>
  );
}

export default Select;
