import { Navigate } from "react-router-dom";
import { adminPayrollLoader } from "~/hooks/admin/queries/use-admin-payroll";
import { alertGroupLoader } from "~/hooks/admin/queries/use-alert";
import { companyByIdLoader, companyLoader } from "~/hooks/admin/queries/use-company";
import { employeeAdminLoader } from "~/hooks/admin/queries/use-employee-admin";
import { adminRolloverRequestDetailsLoader } from "~/hooks/dashboards/queries/use-admin-rollover-request-details";
import { distributionRequestLoader } from "~/hooks/admin/queries/use-distribution-request";
import SuperAdminDashboard from "~/views/admin";
import { AdminErrorBoundary } from "~/components/super-admin/errors/admin-error";
import { schemaLoader } from "~/hooks/dashboards/queries/use-schema-2";
import i18n from "~/i18n";
import { adminOrderLoader } from "~/components/super-admin/pages/orders/api";
import { loanDetailsLoader } from "~/hooks/admin/loans";
import { sentenceCase } from "change-case";
import { securityLoader } from "~/hooks/dashboards/queries/use-security";
import { investmentModelLoader } from "~/hooks/admin/queries/use-investment-models";
import { securityFeeLoader } from "~/hooks/dashboards/queries/use-security-fee";
import { HiddenRouteGuard } from "./app";
import { securityPerformanceLoader } from "~/hooks/admin/queries/use-securities-meta-data";
import { useParams } from "react-router-dom";

const NavigateToDynamicPath = ({ genPath }) => {
  const params = useParams();
  return <Navigate to={genPath(params)} params={params} />;
};

export const getAdminRoutes = ({ t, queryClient }) => {
  return [
    {
      loader: async () => {
        await i18n.loadNamespaces(["admin"]);
        return null;
      },
      children: [
        {
          element: <SuperAdminDashboard />,
          errorElement: <AdminErrorBoundary redirectUnAuthorized />,
          children: [
            {
              path: "/",
              async lazy() {
                let { HomePage } = await import("~/views/admin-views");
                return { Component: HomePage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.dashboard"),
                meta: { title: { key: "breadcrumbs.dashboard" } },
              },
            },
            {
              path: "/alert-groups",
              handle: {
                breadcrumb: t("breadcrumbs.alertGroups.index"),
                meta: { title: { key: "breadcrumbs.alertGroups.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    return {
                      Component: (
                        await import("~/components/super-admin/pages/alert-groups")
                      ).AlertGroupsPage,
                    };
                  },
                },
                {
                  path: ":typeId",
                  loader: alertGroupLoader(queryClient),
                  handle: {
                    breadcrumb: (alert) => {
                      return `${sentenceCase(alert.name)} (${alert.id})`;
                    },
                  },
                  async lazy() {
                    return {
                      Component: (
                        await import(
                          "~/components/super-admin/pages/alert-groups/group-detail"
                        )
                      ).AlertGroupDetailPage,
                    };
                  },
                  children: [
                    {
                      path: ":companyId",
                      loader: companyByIdLoader(queryClient),
                      handle: {
                        breadcrumb: (company) => `${company.name} (${company.id})`,
                        meta: { title: (company) => ({ value: company.name }) },
                      },
                      async lazy() {
                        return {
                          Component: (
                            await import(
                              "~/components/super-admin/pages/alert-groups/group-detail"
                            )
                          ).AlertGroupDetailPage,
                        };
                      },
                      children: [
                        {
                          path: ":groupKey",
                          loader: ({ params: { groupKey } }) =>
                            groupKey.length > 15
                              ? `${groupKey.slice(0, 15)}...`
                              : groupKey,

                          handle: {
                            breadcrumb: (groupKey) => groupKey,
                            meta: { title: (groupKey) => groupKey },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/alert-groups/alert-detail"
                                )
                              ).AlertDetailPage,
                            };
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            {
              path: "/trades",
              handle: {
                breadcrumb: t("breadcrumbs.trades.index"),
                meta: { title: { key: "breadcrumbs.trades.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    const { Trades } = await import(
                      "~/components/super-admin/pages/trade"
                    );
                    return { Component: Trades };
                  },
                },
                {
                  path: ":id",
                  // loader: adminRolloverRequestDetailsLoader(queryClient),
                  async lazy() {
                    let { TradeDetails } = await import(
                      "~/components/super-admin/pages/trade/view"
                    );
                    return { Component: TradeDetails };
                  },
                  // handle: {
                  //   meta: {
                  //     title: (rolloverDetails) => ({
                  //       key: "breadcrumbs.rolloverRequests.show",
                  //       options: {
                  //         employeeName: rolloverDetails.employee.individual.full_name,
                  //         companyName: rolloverDetails.employee.company_name,
                  //       },
                  //     }),
                  //   },
                  //   breadcrumb: (rollover) => {
                  //     if (rollover.employee) {
                  //       return `${rollover.employee.individual.full_name} (${rollover.id})`;
                  //     }
                  //     return rollover.id;
                  //   },
                  // },
                },
              ],
            },

            {
              path: "/transfers",
              handle: {
                breadcrumb: t("breadcrumbs.transfers.create"),
                meta: { title: { key: "breadcrumbs.transfers.create" } },
              },
              async lazy() {
                const { TransfersTabs } = await import(
                  "~/components/super-admin/pages/transfers/index"
                );
                return { Component: TransfersTabs };
              },
              children: [
                {
                  index: true,
                  element: <Navigate to={"/transfers/create"} />,
                },
                {
                  path: "create",
                  handle: {
                    breadcrumb: t("breadcrumbs.transfers.create"),
                    meta: { title: { key: "breadcrumbs.transfers.create" } },
                  },
                  async lazy() {
                    const { CreateTransfer } = await import(
                      "~/components/super-admin/pages/transfers/create-transfers/create"
                    );
                    return { Component: CreateTransfer };
                  },
                  children: [
                    {
                      path: "preview-trade-instructions",
                      handle: {
                        breadcrumb: t("breadcrumbs.transfers.previewTradeInstructions"),
                        meta: {
                          title: {
                            key: "breadcrumbs.transfers.previewTradeInstructions",
                          },
                        },
                      },
                      async lazy() {
                        const { PreviewTradeInstructions } = await import(
                          "~/components/super-admin/pages/transfers/create-transfers/preview-trade-instructions"
                        );
                        return { Component: PreviewTradeInstructions };
                      },
                    },
                    {
                      path: "preview-transactions",
                      handle: {
                        breadcrumb: t("breadcrumbs.transfers.previewTransactions"),
                        meta: {
                          title: { key: "breadcrumbs.transfers.previewTransactions" },
                        },
                      },
                      async lazy() {
                        const { PreviewTransactions } = await import(
                          "~/components/super-admin/pages/transfers/create-transfers/preview-transactions"
                        );
                        return { Component: PreviewTransactions };
                      },
                    },
                  ],
                },
                {
                  path: "bulk-corrections",
                  handle: {
                    breadcrumb: t("breadcrumbs.remediation.index"),
                    meta: { title: { key: "breadcrumbs.remediation.index" } },
                  },
                  async lazy() {
                    let { RemediationPage } = await import("~/views/admin-views");
                    return { Component: RemediationPage };
                  },
                  children: [
                    {
                      index: true,
                      // handle: {
                      //   breadcrumb: t("breadcrumbs.remediation.send"),
                      //   meta: { title: { key: "breadcrumbs.remediation.send" } },
                      // },
                      async lazy() {
                        let { RemediationDownloadPage } = await import(
                          "~/views/admin-views"
                        );
                        return { Component: RemediationDownloadPage };
                      },
                    },
                    {
                      path: "send",
                      handle: {
                        breadcrumb: t("breadcrumbs.remediation.send"),
                        meta: { title: { key: "breadcrumbs.remediation.send" } },
                      },
                      async lazy() {
                        let { RemediationSendPage } = await import("~/views/admin-views");
                        return { Component: RemediationSendPage };
                      },
                    },
                    {
                      path: "upload",
                      handle: {
                        breadcrumb: t("breadcrumbs.remediation.upload"),
                        meta: { title: { key: "breadcrumbs.remediation.upload" } },
                      },
                      async lazy() {
                        let { RemediationUploadPage } = await import(
                          "~/views/admin-views"
                        );
                        return { Component: RemediationUploadPage };
                      },
                    },
                  ],
                },
              ],
            },

            {
              path: "/investments",
              handle: {
                breadcrumb: t("breadcrumbs.investments.index"),
                meta: { title: { key: "breadcrumbs.investments.index" } },
              },
              async lazy() {
                return {
                  Component: (
                    await import("~/components/super-admin/pages/investments/index")
                  ).InvestmentsTabs,
                };
              },
              children: [
                {
                  index: true,
                  element: <Navigate to={"/investments/securities"} />,
                },
                {
                  path: "securities",
                  handle: {
                    breadcrumb: t("breadcrumbs.investments.securities"),
                    meta: {
                      title: {
                        key: "breadcrumbs.investments.tradeRestrictions",
                      },
                    },
                  },
                  async lazy() {
                    let { Securities } = await import(
                      "~/components/super-admin/pages/investments/securities/index"
                    );
                    return { Component: Securities };
                  },
                },
                {
                  path: "models",
                  handle: {
                    breadcrumb: t("breadcrumbs.investments.models"),
                    meta: { title: { key: "breadcrumbs.investments.models" } },
                  },
                  async lazy() {
                    const { InvestmentModels } = await import(
                      "~/components/super-admin/pages/investments/models"
                    );
                    return { Component: InvestmentModels };
                  },
                },
                {
                  path: "trade-restrictions",
                  handle: {
                    breadcrumb: t("breadcrumbs.companies.tabs.tradeRestrictions"),
                    meta: {
                      title: {
                        key: "breadcrumbs.companies.tabs.tradeRestrictions",
                      },
                    },
                  },
                  children: [
                    {
                      index: true,
                      async lazy() {
                        let { TradeRestrictionsPage } = await import(
                          "~/components/super-admin/pages/trade-restrictions/index"
                        );
                        return { Component: TradeRestrictionsPage };
                      },
                    },
                  ],
                },
                {
                  path: "performance",
                  handle: {
                    breadcrumb: t("breadcrumbs.performance"),
                    meta: { title: { key: "breadcrumbs.performance" } },
                  },
                  children: [
                    {
                      index: true,
                      async lazy() {
                        let { SecuritiesPerformancePage } = await import(
                          "~/components/super-admin/pages/securities-meta/index"
                        );
                        return { Component: SecuritiesPerformancePage };
                      },
                    },
                    {
                      path: "upload",
                      async lazy() {
                        let { UploadPerformanceDataTab } = await import(
                          "~/views/admin-views"
                        );
                        return { Component: UploadPerformanceDataTab };
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "/investments/models/create",
              async lazy() {
                const { InvestmentModelCreate } = await import(
                  "~/components/super-admin/pages/investments/models/create"
                );
                return { Component: InvestmentModelCreate };
              },
              handle: {
                breadcrumb: [
                  {
                    pathname: "/investments",
                    breadcrumb: t("breadcrumbs.investments.index"),
                  },
                  {
                    pathname: "/investments/models",
                    breadcrumb: t("breadcrumbs.investments.models"),
                  },
                  {
                    pathname: "/investments/models/create",
                    breadcrumb: t("breadcrumbs.investmentModels.create"),
                  },
                ],
                meta: { title: { key: "breadcrumbs.investmentModels.create" } },
              },
            },
            {
              path: "/investments/models/:securityGroupUid",
              loader: investmentModelLoader(queryClient),
              async lazy() {
                const { InvestmentModelDetails } = await import(
                  "~/components/super-admin/pages/investments/models/details"
                );
                return { Component: InvestmentModelDetails };
              },
              handle: {
                breadcrumb: [
                  {
                    pathname: "/investments",
                    breadcrumb: t("breadcrumbs.investments.index"),
                  },
                  {
                    pathname: "/investments/models",
                    breadcrumb: t("breadcrumbs.investments.models"),
                  },
                  {
                    pathname: (model) => `/investments/models/${model.u_id}`,
                    breadcrumb: (model) => {
                      return model.name;
                    },
                  },
                ],
              },
            },
            {
              path: "/investments/models/:securityGroupUid/edit",
              loader: investmentModelLoader(queryClient),
              async lazy() {
                const { InvestmentModelEdit } = await import(
                  "~/components/super-admin/pages/investments/models/edit"
                );
                return { Component: InvestmentModelEdit };
              },
              handle: {
                breadcrumb: [
                  {
                    pathname: "/investments",
                    breadcrumb: t("breadcrumbs.investments.index"),
                  },
                  {
                    pathname: "/investments/models",
                    breadcrumb: t("breadcrumbs.investments.models"),
                  },
                  {
                    pathname: (model) => `/investments/models/${model.u_id}`,
                    breadcrumb: (model) => {
                      return model.name;
                    },
                  },
                  {
                    pathname: (model) => `/investments/models/${model.u_id}/edit`,
                    breadcrumb: t("breadcrumbs.investmentModels.edit"),
                  },
                ],
                meta: { title: { key: "breadcrumbs.investmentModels.edit" } },
              },
            },
            {
              path: "/investments/securities/create",
              handle: {
                meta: {
                  title: () => ({
                    key: "breadcrumbs.investments.createSecurity",
                  }),
                },
                breadcrumb: [
                  {
                    pathname: "/investments",
                    breadcrumb: t("breadcrumbs.investments.index"),
                  },
                  {
                    pathname: "/investments/securities",
                    breadcrumb: t("breadcrumbs.investments.securities"),
                  },
                  {
                    pathname: () => `/investments/securities/create`,
                    breadcrumb: t("breadcrumbs.investments.createSecurity"),
                  },
                ],
              },
              async lazy() {
                return {
                  Component: (
                    await import(
                      "~/components/super-admin/pages/investments/securities/create/index"
                    )
                  ).SecurityCreate,
                };
              },
            },
            {
              path: "/investments/securities/:id",
              loader: securityLoader(queryClient),
              handle: {
                meta: {
                  title: () => ({
                    key: "breadcrumbs.investments.securities",
                  }),
                },
                breadcrumb: [
                  {
                    pathname: "/investments",
                    breadcrumb: t("breadcrumbs.investments.index"),
                  },
                  {
                    pathname: "/investments/securities",
                    breadcrumb: t("breadcrumbs.investments.securities"),
                  },
                  {
                    pathname: (security) =>
                      `/investments/securities/${security.id}/details`,
                    breadcrumb: (security) => `${security.name}`,
                  },
                ],
              },
              children: [
                {
                  path: "details",
                  async lazy() {
                    return {
                      Component: (
                        await import(
                          "~/components/super-admin/pages/investments/securities/security"
                        )
                      ).SecurityDetailsPage,
                    };
                  },
                  children: [
                    {
                      index: true,
                      async lazy() {
                        return {
                          Component: (
                            await import(
                              "~/components/super-admin/pages/investments/securities/security/details"
                            )
                          ).SecurityDetailsTab,
                        };
                      },
                    },
                    {
                      path: "fees",
                      async lazy() {
                        return {
                          Component: (
                            await import(
                              "~/components/super-admin/pages/investments/securities/security/fees/index"
                            )
                          ).FeesTab,
                        };
                      },
                    },
                    {
                      path: "performance",
                      async lazy() {
                        return {
                          Component: (
                            await import(
                              "~/components/super-admin/pages/investments/securities/security/performance/index"
                            )
                          ).SecurityPerformanceTable,
                        };
                      },
                    },
                    {
                      path: "history",
                      async lazy() {
                        return {
                          Component: (
                            await import(
                              "~/components/super-admin/pages/investments/securities/security/history"
                            )
                          ).SecurityHistoryTab,
                        };
                      },
                    },
                  ],
                },
                {
                  path: "fees",
                  loader: securityLoader(queryClient),
                  handle: {
                    meta: {
                      title: () => ({
                        key: "breadcrumbs.investments.fees",
                      }),
                    },
                    breadcrumb: [
                      {
                        pathname: (security) =>
                          `/investments/securities/${security.id}/details/fees`,
                        breadcrumb: `Fees`,
                      },
                    ],
                  },
                  children: [
                    {
                      path: "create",
                      async lazy() {
                        let { SecurityFeeCreate } = await import(
                          "~/components/super-admin/pages/investments/securities/security/fees/create-fee/index"
                        );
                        return { Component: SecurityFeeCreate };
                      },
                    },
                    {
                      path: ":fee_id",
                      loader: securityFeeLoader(queryClient),
                      handle: {
                        meta: {
                          title: () => ({
                            key: "breadcrumbs.investments.fee",
                          }),
                        },
                        breadcrumb: [
                          {
                            pathname: (fee) =>
                              `/investments/securities/${fee.security_id}/fees/${fee.id}`,
                            breadcrumb: (fee) => `(${fee.id})`,
                          },
                        ],
                      },
                      children: [
                        {
                          index: true,
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/investments/securities/security/fees/view-fee/index"
                                )
                              ).SecurityFeeDetailsTab,
                            };
                          },
                        },
                        {
                          path: "edit",
                          async lazy() {
                            let { SecurityFeeUpdate } = await import(
                              "~/components/super-admin/pages/investments/securities/security/fees/update-fee/index"
                            );
                            return { Component: SecurityFeeUpdate };
                          },
                        },
                      ],
                    },
                  ],
                },

                {
                  path: "performance",
                  loader: securityLoader(queryClient),
                  handle: {
                    breadcrumb: [
                      {
                        pathname: (security) =>
                          `/investments/securities/${security.id}/details/performance`,
                        breadcrumb: `Performance`,
                      },
                    ],
                  },
                  children: [
                    {
                      path: "create",
                      async lazy() {
                        let { SecurityPerformanceCreate } = await import(
                          "~/components/super-admin/pages/securities-meta/create/index"
                        );
                        return { Component: SecurityPerformanceCreate };
                      },
                    },
                    {
                      path: ":performance_id",
                      loader: securityPerformanceLoader(queryClient),
                      handle: {
                        meta: {
                          title: () => ({
                            key: "breadcrumbs.investments.performance",
                          }),
                        },
                        breadcrumb: [
                          {
                            pathname: (performanceRow) =>
                              `/investments/securities/${performanceRow.security_id}/performance/${performanceRow.id}`,
                            breadcrumb: (performanceRow) => `(${performanceRow.id})`,
                          },
                        ],
                      },
                      children: [
                        {
                          index: true,
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/securities-meta/details/index"
                                )
                              ).PerformanceDetails,
                            };
                          },
                        },
                        {
                          path: "edit",
                          async lazy() {
                            let { SecurityPerformanceUpdate } = await import(
                              "~/components/super-admin/pages/securities-meta/update/index"
                            );
                            return { Component: SecurityPerformanceUpdate };
                          },
                        },
                      ],
                    },
                  ],
                },

                {
                  path: "edit",
                  loader: securityLoader(queryClient),
                  handle: {
                    meta: {
                      title: () => ({
                        key: "breadcrumbs.investments.editSecurity",
                      }),
                    },
                    breadcrumb: t("breadcrumbs.investments.editSecurity"),
                  },
                  async lazy() {
                    return {
                      Component: (
                        await import(
                          "~/components/super-admin/pages/investments/securities/security/update.jsx"
                        )
                      ).SecurityUpdate,
                    };
                  },
                },
              ],
            },
            {
              path: "/investments/trade-restrictions/edit",
              handle: {
                breadcrumb: [
                  {
                    pathname: "/investments",
                    breadcrumb: t("breadcrumbs.investments.index"),
                  },
                  {
                    pathname: "/investments/trade-restrictions",
                    breadcrumb: t("breadcrumbs.companies.tabs.tradeRestrictions"),
                  },
                  {
                    pathname: "/investments/trade-restrictions/edit",
                    breadcrumb: "Edit",
                  },
                ],
                meta: {
                  title: {
                    key: "breadcrumbs.companies.tabs.employees.employee.investments",
                  },
                },
              },
              async lazy() {
                let { EditTradeRestrictions } = await import(
                  "~/components/super-admin/features/trade-restrictions/edit-restrictions"
                );
                return { Component: EditTradeRestrictions };
              },
            },
            {
              path: "/investments/trade-restrictions/create",
              handle: {
                breadcrumb: [
                  {
                    pathname: "/investments",
                    breadcrumb: t("breadcrumbs.investments.index"),
                  },
                  {
                    pathname: "/investments/trade-restrictions",
                    breadcrumb: t("breadcrumbs.companies.tabs.tradeRestrictions"),
                  },
                  { pathname: "/trade-restrictions/create", breadcrumb: "Create" },
                ],
                meta: {
                  title: {
                    key: "breadcrumbs.companies.tabs.employees.employee.investments",
                  },
                },
              },
              async lazy() {
                let { CreateTradeRestrictions } = await import(
                  "~/components/super-admin/pages/trade-restrictions/create"
                );
                return { Component: CreateTradeRestrictions };
              },
            },

            {
              path: "/companies",
              handle: {
                breadcrumb: t("breadcrumbs.companies.index"),
                meta: { title: { key: "breadcrumbs.companies.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    let { CompaniesPage } = await import("~/views/admin-views");
                    return { Component: CompaniesPage };
                  },
                },
                {
                  path: "create",
                  async lazy() {
                    let { CreateCompanyPage } = await import("~/views/admin-views");
                    return { Component: CreateCompanyPage };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.companies.create"),
                    meta: { title: { key: "breadcrumbs.companies.create" } },
                  },
                },
                {
                  path: ":companyUId",
                  loader: companyLoader(queryClient),
                  handle: {
                    breadcrumb: (company) => `${company.name} (${company.u_id})`,
                    meta: { title: (company) => ({ value: company.name }) },
                  },
                  children: [
                    {
                      async lazy() {
                        let { CompanyPage } = await import("~/views/admin-views");
                        return { Component: CompanyPage };
                      },
                      children: [
                        {
                          index: true,
                          element: <Navigate to="company" />,
                        },
                        {
                          path: "company",
                          async lazy() {
                            let { CompanyTab } = await import("~/views/admin-views");
                            return { Component: CompanyTab };
                          },
                        },
                        {
                          path: "investments",
                          loader: companyLoader(queryClient),
                          handle: {
                            breadcrumb: [
                              {
                                pathname: (company) =>
                                  `/companies/${company.u_id}/investments/securities`,
                                breadcrumb: t("breadcrumbs.companies.tabs.investments"),
                              },
                            ],
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.investments" },
                            },
                          },
                          async lazy() {
                            let { InvestmentsTabs } = await import(
                              "~/components/super-admin/pages/view-company/investments-tab/index"
                            );
                            return { Component: InvestmentsTabs };
                          },
                          children: [
                            { index: true, element: <Navigate to="securities" /> },
                            {
                              path: "securities",
                              children: [
                                {
                                  index: true,
                                  async lazy() {
                                    let { SecurityPlanAssociations } = await import(
                                      "~/components/super-admin/pages/view-company/investments-tab/security-plan-association/list/index"
                                    );
                                    return { Component: SecurityPlanAssociations };
                                  },
                                },
                                {
                                  path: "add-security",
                                  async lazy() {
                                    let { AssociatePlanSecurity } = await import(
                                      "~/components/super-admin/pages/view-company/investments-tab/security-plan-association/create/index"
                                    );
                                    return { Component: AssociatePlanSecurity };
                                  },
                                },
                                {
                                  path: ":associationId/edit",
                                  async lazy() {
                                    let { UpdatePlanSecurity } = await import(
                                      "~/components/super-admin/pages/view-company/investments-tab/security-plan-association/edit/index"
                                    );
                                    return { Component: UpdatePlanSecurity };
                                  },
                                },
                              ],
                            },
                            {
                              path: "models",
                              children: [
                                {
                                  index: true,
                                  async lazy() {
                                    let { SecurityGroupPlanAssociations } = await import(
                                      "~/components/super-admin/pages/view-company/investments-tab/investment-models/list/index"
                                    );
                                    return { Component: SecurityGroupPlanAssociations };
                                  },
                                },
                                {
                                  path: "add-model",
                                  async lazy() {
                                    let { AssociateSecurityGroupPlanSecurity } =
                                      await import(
                                        "~/components/super-admin/pages/view-company/investments-tab/investment-models/create/index"
                                      );
                                    return {
                                      Component: AssociateSecurityGroupPlanSecurity,
                                    };
                                  },
                                },
                                {
                                  path: ":associationId/edit",
                                  async lazy() {
                                    let { UpdateSecurityGroupPlanSecurity } =
                                      await import(
                                        "~/components/super-admin/pages/view-company/investments-tab/investment-models/edit/index"
                                      );
                                    return { Component: UpdateSecurityGroupPlanSecurity };
                                  },
                                },
                              ],
                            },
                            {
                              path: "qdia-setup",
                              async lazy() {
                                let { QDIASetup } = await import(
                                  "~/components/super-admin/pages/view-company/investments-tab/qdia-setup/index"
                                );
                                return { Component: QDIASetup };
                              },
                            },
                          ],
                        },
                        {
                          path: "documents",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.documents"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.documents" },
                            },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/documents-tab"
                                )
                              ).DocumentsTab,
                            };
                          },
                        },
                        {
                          path: "data-exchange",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.importExport"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.importExport" },
                            },
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                return {
                                  Component: (
                                    await import(
                                      "~/components/super-admin/pages/view-company/import-and-export-tab"
                                    )
                                  ).ImportAndExportTab,
                                };
                              },
                            },
                            {
                              path: ":schemaSlug",
                              loader: async ({ params: { schemaSlug } }) => {
                                const schema = await schemaLoader(queryClient)();
                                const schemaName = Object.values(schema.components).find(
                                  (v) => v["x-slug"] === schemaSlug
                                )?.title;
                                if (!(schemaName in schema.components)) {
                                  throw new Response("Not Found", { status: 404 });
                                }

                                return {
                                  schema,
                                  schemaName,
                                };
                              },
                              handle: {
                                meta: {
                                  title: ({ schema, schemaName }) => ({
                                    value:
                                      schema.components[schemaName]?.["x-human-title"] ||
                                      schema.components[schemaName]?.title,
                                  }),
                                },
                                breadcrumb: ({ schema, schemaName }) => {
                                  return (
                                    schema.components[schemaName]?.["x-human-title"] ||
                                    schema.components[schemaName]?.title
                                  );
                                },
                              },

                              async lazy() {
                                return {
                                  Component: (
                                    await import(
                                      "~/components/super-admin/pages/view-company/import-and-export-tab/form-page"
                                    )
                                  ).ImportAndExportFormPage,
                                };
                              },
                            },
                          ],
                        },
                        {
                          path: "employees",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.employees.index"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.employees.index",
                              },
                            },
                          },
                          lazy: async () => ({
                            Component: (
                              await import(
                                "~/components/super-admin/pages/view-company/employees-tab"
                              )
                            ).EmployeesTab,
                          }),
                        },
                        {
                          path: "payrolls",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.payrolls"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.payrolls" },
                            },
                          },
                          lazy: async () => ({
                            Component: (
                              await import(
                                "~/components/super-admin/pages/view-company/payrolls-tab"
                              )
                            ).PayrollsTab,
                          }),
                        },
                        {
                          path: "admin",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.admins"),
                            meta: { title: { key: "breadcrumbs.companies.tabs.admins" } },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/admins-tab"
                                )
                              ).AdminsTab,
                            };
                          },
                        },
                        {
                          path: "account",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.account"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.account" },
                            },
                          },
                          async lazy() {
                            let { AccountTab } = await import("~/views/admin-views");
                            return { Component: AccountTab };
                          },
                        },
                        {
                          path: "config",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.planConfig"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.planConfig",
                              },
                            },
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                let { PlanConfig } = await import("~/views/admin-views");
                                return { Component: PlanConfig };
                              },
                            },
                          ],
                        },
                        {
                          path: "custodian",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.custodian"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.custodian" },
                            },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/custodian-tab"
                                )
                              ).CustodianTab,
                            };
                          },
                        },
                        {
                          path: "transactions",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.transactions"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.transactions" },
                            },
                          },
                          async lazy() {
                            let { TransactionsTab } = await import("~/views/admin-views");
                            return { Component: TransactionsTab };
                          },
                        },
                        {
                          path: "add-contributions",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.addContributions"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.addContributions",
                              },
                            },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/add-contributions-tab"
                                )
                              ).AddContributionsTab,
                            };
                          },
                        },
                        {
                          path: "external-benefits",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.externalBenefits"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.externalBenefits",
                              },
                            },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/external-benefits-tab"
                                )
                              ).ExternalBenefitsPage,
                            };
                          },
                        },
                        {
                          path: "custodian",
                          async lazy() {
                            let { Custodian } = await import(
                              "~/components/super-admin/pages/view-company/custodian"
                            );
                            return { Component: Custodian };
                          },
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.custodian.index"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.custodian.index",
                              },
                            },
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                let { Activity } = await import(
                                  "~/components/super-admin/pages/view-company/custodian/activity"
                                );
                                return { Component: Activity };
                              },
                              // handle: {
                              //   breadcrumb: t(
                              //     "breadcrumbs.companies.tabs.custodian.activity"
                              //   ),
                              //   meta: {
                              //     title: {
                              //       key: "breadcrumbs.companies.tabs.custodian.activity",
                              //     },
                              //   },
                              // },
                            },
                            {
                              path: "positions",
                              async lazy() {
                                let { Positions } = await import(
                                  "~/components/super-admin/pages/view-company/custodian/positions"
                                );
                                return { Component: Positions };
                              },
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.custodian.positions"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.custodian.positions",
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          path: "edit",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.edit"),
                            meta: { title: { key: "breadcrumbs.companies.edit" } },
                          },
                          async lazy() {
                            let { EditCompanyPage } = await import("~/views/admin-views");
                            return { Component: EditCompanyPage };
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                let { EditCompanyForm } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EditCompanyForm };
                              },
                            },
                            {
                              path: "eligibility",
                              async lazy() {
                                let { EligibilityForm } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EligibilityForm };
                              },
                            },
                            {
                              path: "billing",
                              async lazy() {
                                let { BillingConfigForm } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: BillingConfigForm };
                              },
                            },
                            {
                              path: "addresses",
                              async lazy() {
                                let { EditCompanyAddresses } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EditCompanyAddresses };
                              },
                            },
                            {
                              path: "payroll-schedule",
                              async lazy() {
                                let { PayrollSchedules } = await import(
                                  "~/components/super-admin/pages/edit-company/payroll-schedules"
                                );
                                return { Component: PayrollSchedules };
                              },
                            },
                          ],
                        },
                      ],
                    },

                    {
                      // separated from the "employees" path above so that the layout is not nested
                      path: "employees",
                      handle: {
                        breadcrumb: t("breadcrumbs.companies.tabs.employees.index"),
                        meta: {
                          title: { key: "breadcrumbs.companies.tabs.employees.index" },
                        },
                      },
                      children: [
                        {
                          path: ":employeeUId",
                          loader: employeeAdminLoader(queryClient),
                          handle: {
                            breadcrumb: (employee) =>
                              `${employee.individual.full_name} (${employee.u_id})`,
                            meta: {
                              title: (employee) => ({
                                value: employee.individual.full_name,
                              }),
                            },
                          },
                          async lazy() {
                            let { EmployeePage } = await import("~/views/admin-views");
                            return { Component: EmployeePage };
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                let { EmployeeTab } = await import("~/views/admin-views");
                                return { Component: EmployeeTab };
                              },
                            },
                            {
                              path: "edit",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.edit"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.edit",
                                  },
                                },
                              },
                              async lazy() {
                                let { EditEmployeePage } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EditEmployeePage };
                              },
                              children: [
                                {
                                  index: true,
                                  async lazy() {
                                    let { EmployeeForm } = await import(
                                      "~/views/admin-views"
                                    );
                                    return { Component: EmployeeForm };
                                  },
                                },
                                {
                                  path: "addresses",
                                  async lazy() {
                                    let { AddressesForm } = await import(
                                      "~/views/admin-views"
                                    );
                                    return { Component: AddressesForm };
                                  },
                                },
                                {
                                  path: "plan-contributions",
                                  async lazy() {
                                    let { ContributionsForm } = await import(
                                      "~/views/admin-views"
                                    );
                                    return { Component: ContributionsForm };
                                  },
                                },
                              ],
                            },
                            {
                              path: "plan",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.retirementPlan"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.retirementPlan",
                                  },
                                },
                              },
                              async lazy() {
                                let { EmployeeRetirementPlanTab } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EmployeeRetirementPlanTab };
                              },
                            },
                            {
                              path: "account",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.account"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.account",
                                  },
                                },
                              },
                              async lazy() {
                                let { EmployeeAccount } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EmployeeAccount };
                              },
                            },
                            {
                              path: "external-statuses",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.externalBenefitStatuses"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.externalBenefitStatuses",
                                  },
                                },
                              },
                              async lazy() {
                                let { ExternalBenefitStatuses } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: ExternalBenefitStatuses };
                              },
                            },
                            {
                              element: <HiddenRouteGuard redirectTo="/" />,
                              children: [
                                {
                                  path: "loans",
                                  handle: {
                                    breadcrumb: t(
                                      "breadcrumbs.companies.tabs.employees.employee.loans"
                                    ),
                                    meta: {
                                      title: {
                                        key: "breadcrumbs.companies.tabs.employees.employee.loans",
                                      },
                                    },
                                  },
                                  async lazy() {
                                    let { EmployeeLoansTab } = await import(
                                      "~/components/super-admin/pages/view-employee/loans-tab"
                                    );
                                    return { Component: EmployeeLoansTab };
                                  },
                                },
                              ],
                            },
                            {
                              path: "elections",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.elections"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.elections",
                                  },
                                },
                              },
                              async lazy() {
                                let { EmployeeElectionsTab } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EmployeeElectionsTab };
                              },
                            },
                            {
                              path: "account-split",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.accountSplit"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.accountSplit",
                                  },
                                },
                              },
                              async lazy() {
                                let { AccountSplit } = await import(
                                  "~/components/super-admin/pages/view-employee/account-split"
                                );
                                return { Component: AccountSplit };
                              },
                            },
                            {
                              path: "beneficiaries",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.beneficiaries"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.beneficiaries",
                                  },
                                },
                              },
                              async lazy() {
                                let { BeneficiariesWrapper } = await import(
                                  "~/components/super-admin/pages/view-employee/beneficiaries/index"
                                );
                                return { Component: BeneficiariesWrapper };
                              },
                              children: [
                                {
                                  index: true,
                                  async lazy() {
                                    let { ListBeneficiaries } = await import(
                                      "~/components/super-admin/pages/view-employee/beneficiaries/list"
                                    );
                                    return { Component: ListBeneficiaries };
                                  },
                                },
                                {
                                  path: "manage",
                                  handle: {
                                    breadcrumb: t(
                                      "breadcrumbs.companies.tabs.employees.employee.beneficiariesAdd"
                                    ),
                                    meta: {
                                      title: {
                                        key: "breadcrumbs.companies.tabs.employees.employee.beneficiaries",
                                      },
                                    },
                                  },
                                  async lazy() {
                                    let { ManageBeneficiaries } = await import(
                                      "~/components/super-admin/pages/view-employee/beneficiaries/manage"
                                    );
                                    return { Component: ManageBeneficiaries };
                                  },
                                },
                              ],
                            },
                            {
                              path: "notifications",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.notifications"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.notifications",
                                  },
                                },
                              },
                              async lazy() {
                                let { NotificationsPage } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: NotificationsPage };
                              },
                            },
                            {
                              path: "transactions",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.transactions"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.transactions",
                                  },
                                },
                              },
                              async lazy() {
                                let { EmployeeTransactionsTab } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EmployeeTransactionsTab };
                              },
                            },
                            {
                              path: "trade-restrictions",
                              async lazy() {
                                let { TradeRestrictionsList } = await import(
                                  "~/components/super-admin/pages/view-employee/trade-restrictions"
                                );
                                return { Component: TradeRestrictionsList };
                              },
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.tradeRestrictions"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.tradeRestrictions",
                                  },
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      // separated from the "config" path above so that the layout is not nested
                      path: "config",
                      handle: {
                        breadcrumb: t("breadcrumbs.companies.tabs.planConfig"),
                        meta: {
                          title: {
                            key: "breadcrumbs.companies.tabs.planConfig",
                          },
                        },
                      },
                      children: [
                        {
                          path: "edit",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.editPlanConfig"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.editPlanConfig",
                              },
                            },
                          },
                          async lazy() {
                            let { EditPlanConfig } = await import("~/views/admin-views");
                            return { Component: EditPlanConfig };
                          },
                        },
                        {
                          path: "create",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.createPlanConfig"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.createPlanConfig",
                              },
                            },
                          },
                          async lazy() {
                            let { CreatePlanConfig } = await import(
                              "~/views/admin-views"
                            );
                            return { Component: CreatePlanConfig };
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "/financials",
              async lazy() {
                let { FinancialsPage } = await import("~/views/admin-views");
                return { Component: FinancialsPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.financials"),
                meta: { title: { key: "breadcrumbs.financials" } },
              },
            },
            {
              path: "/reports",
              handle: {
                breadcrumb: t("breadcrumbs.reports.index"),
                meta: { title: { key: "breadcrumbs.reports.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    let { Reports } = await import(
                      "~/components/super-admin/pages/reports"
                    );
                    return { Component: Reports };
                  },
                },
                {
                  path: "create/:reportSlug",
                  loader: async ({ params: { reportSlug } }) => {
                    return {
                      reportSlug,
                    };
                  },
                  async lazy() {
                    let { CreateReport } = await import(
                      "~/components/super-admin/pages/reports/create"
                    );
                    return { Component: CreateReport };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.reports.create"),
                    meta: { title: { key: "breadcrumbs.reports.create" } },
                  },
                },
                {
                  path: "edit/:reportSlug/:UID",
                  loader: async ({ params: { reportSlug, UID } }) => {
                    return {
                      reportSlug,
                      UID,
                    };
                  },
                  async lazy() {
                    let { UpdateReport } = await import(
                      "~/components/super-admin/pages/reports/edit"
                    );
                    return { Component: UpdateReport };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.reports.edit"),
                    meta: { title: { key: "breadcrumbs.reports.edit" } },
                  },
                },
              ],
            },
            {
              path: "/data-exchange",
              handle: {
                breadcrumb: t("breadcrumbs.importExport"),
                meta: { title: { key: "breadcrumbs.importExport" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    return {
                      Component: (
                        await import("~/components/super-admin/pages/import-and-export")
                      ).ImportAndExportPage,
                    };
                  },
                },
                {
                  path: ":schemaSlug",
                  loader: async ({ params: { schemaSlug } }) => {
                    const schema = await schemaLoader(queryClient)();

                    const schemaName = Object.values(schema.components).find(
                      (v) => v["x-slug"] === schemaSlug
                    )?.title;
                    if (!(schemaName in schema.components)) {
                      throw new Response("Not Found", { status: 404 });
                    }

                    return {
                      schema,
                      schemaName,
                    };
                  },
                  handle: {
                    meta: {
                      title: ({ schema, schemaName }) => ({
                        value:
                          schema.components[schemaName]?.["x-human-title"] ||
                          schema.components[schemaName]?.title,
                      }),
                    },
                    breadcrumb: ({ schema, schemaName }) => {
                      return (
                        schema.components[schemaName]?.["x-human-title"] ||
                        schema.components[schemaName]?.title
                      );
                    },
                  },

                  async lazy() {
                    return {
                      Component: (
                        await import(
                          "~/components/super-admin/pages/import-and-export/form-page"
                        )
                      ).ImportAndExportFormPage,
                    };
                  },
                },
              ],
            },
            {
              path: "/orders",
              handle: {
                breadcrumb: t("breadcrumbs.orders.index"),
                meta: { title: { key: "breadcrumbs.orders.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    let { Orders } = await import(
                      "~/components/super-admin/pages/orders/orders"
                    );
                    return { Component: Orders };
                  },
                },
                {
                  path: "create/deposit",
                  async lazy() {
                    let { CreateDeposit } = await import(
                      "~/components/super-admin/pages/orders/create-ach"
                    );
                    return { Component: CreateDeposit };
                  },
                },
                {
                  path: ":id",
                  loader: adminOrderLoader(queryClient),
                  handle: {
                    meta: {
                      title: (order) => ({
                        key: "breadcrumbs.orders.show",
                        options: { orderUId: order.u_id },
                      }),
                    },
                    breadcrumb: (order) => order.u_id,
                  },
                  children: [
                    {
                      index: true,
                      element: (
                        <NavigateToDynamicPath
                          genPath={(params) => `/orders/${params.id}/details`}
                        />
                      ),
                    },
                    {
                      path: "details",
                      async lazy() {
                        let { OrderTabs } = await import(
                          "~/components/super-admin/pages/orders/order/index"
                        );
                        return { Component: OrderTabs };
                      },
                      children: [
                        {
                          index: true,
                          async lazy() {
                            let { OrderDetails } = await import(
                              "~/components/super-admin/pages/orders/order/details/index"
                            );
                            return { Component: OrderDetails };
                          },
                        },
                        {
                          path: "trade-instructions",
                          async lazy() {
                            let { OrderTradeInstructions } = await import(
                              "~/components/super-admin/pages/orders/order/trade-instructions/index"
                            );
                            return { Component: OrderTradeInstructions };
                          },
                        },
                        {
                          path: "transactions",
                          async lazy() {
                            let { OrderTransactions } = await import(
                              "~/components/super-admin/pages/orders/order/transactions/index"
                            );
                            return { Component: OrderTransactions };
                          },
                        },
                      ],
                    },
                    {
                      path: "activity",
                      handle: {
                        breadcrumb: t("breadcrumbs.orders.matchCustodianActivity"),
                        meta: {
                          title: {
                            key: "breadcrumbs.orders.matchCustodianActivity",
                          },
                        },
                      },
                      async lazy() {
                        let { MatchCustodianActivity } = await import(
                          "~/components/super-admin/pages/orders/match-custodian-activity"
                        );
                        return { Component: MatchCustodianActivity };
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "/jobs",
              async lazy() {
                let { TasksPage } = await import("~/views/admin-views");
                return { Component: TasksPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.jobs"),
                meta: { title: { key: "breadcrumbs.jobs" } },
              },
            },
            {
              path: "/jobs-periodic",
              async lazy() {
                let { TasksPeriodicPage } = await import("~/views/admin-views");
                return { Component: TasksPeriodicPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.jobsPeriodic"),
                meta: { title: { key: "breadcrumbs.jobsPeriodic" } },
              },
            },
            {
              path: "/payrolls",
              handle: {
                breadcrumb: t("breadcrumbs.payrolls.index"),
                meta: { title: { key: "breadcrumbs.payrolls.index" } },
              },

              children: [
                {
                  index: true,
                  async lazy() {
                    return {
                      Component: (await import("~/components/super-admin/payrolls-page"))
                        .PayrollsPage,
                    };
                  },
                },
                {
                  path: ":id",
                  loader: adminPayrollLoader(queryClient),
                  handle: {
                    meta: {
                      title: (payroll) => ({
                        key: "breadcrumbs.payrolls.show",
                        options: { payrollId: payroll.u_id },
                      }),
                    },
                    breadcrumb: (payroll) => `${payroll.company_name} (${payroll.u_id})`,
                  },
                  async lazy() {
                    return {
                      Component: (
                        await import(
                          "~/components/super-admin/pages/payrolls/payroll-details"
                        )
                      ).PayrollDetailsPage,
                    };
                  },
                },
              ],
            },
            {
              path: "/documents",
              async lazy() {
                let { UploadDocsPage } = await import("~/views/admin-views");
                return { Component: UploadDocsPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.documents"),
                meta: { title: { key: "breadcrumbs.documents" } },
              },
            },
            {
              path: "/invites",
              children: [
                {
                  index: true,

                  async lazy() {
                    let { InvitesPage } = await import("~/views/admin-views");
                    return { Component: InvitesPage };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.invitations.index"),
                    meta: { title: { key: "breadcrumbs.invitations.index" } },
                  },
                },

                {
                  path: "create",
                  async lazy() {
                    let { CreateInvitePage } = await import("~/views/admin-views");
                    return { Component: CreateInvitePage };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.invitations.create"),
                    meta: { title: { key: "breadcrumbs.invitations.create" } },
                  },
                },
              ],
            },
            {
              path: "/notifications",
              children: [
                {
                  index: true,

                  async lazy() {
                    let { NotificationsWithPageTitle } = await import(
                      "~/views/admin-views"
                    );
                    return { Component: NotificationsWithPageTitle };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.notifications"),
                    meta: { title: { key: "breadcrumbs.notifications" } },
                  },
                },
              ],
            },
            {
              path: "/external-activities",
              async lazy() {
                let { ExternalActivities } = await import("~/views/admin-views");
                return { Component: ExternalActivities };
              },
              handle: {
                breadcrumb: t("breadcrumbs.externalActivities"),
                meta: { title: { key: "breadcrumbs.externalActivities" } },
              },
            },
            {
              path: "rmd",
              async lazy() {
                let { RMDPage } = await import("~/views/admin-views");
                return { Component: RMDPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.RMD"),
                meta: { title: { key: "breadcrumbs.RMD" } },
              },
            },
            {
              path: "/rollover-requests",
              handle: {
                breadcrumb: t("breadcrumbs.rolloverRequests.index"),
                meta: { title: { key: "breadcrumbs.rolloverRequests.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    let { RolloverRequestsPage } = await import("~/views/admin-views");
                    return { Component: RolloverRequestsPage };
                  },
                },
                {
                  path: ":id",
                  loader: adminRolloverRequestDetailsLoader(queryClient),
                  async lazy() {
                    let { RolloverDetails } = await import("~/views/admin-views");
                    return { Component: RolloverDetails };
                  },
                  handle: {
                    meta: {
                      title: (rolloverDetails) => ({
                        key: "breadcrumbs.rolloverRequests.show",
                        options: {
                          employeeName: rolloverDetails.employee.individual.full_name,
                          companyName: rolloverDetails.employee.company_name,
                        },
                      }),
                    },
                    breadcrumb: (rollover) => {
                      if (rollover.employee) {
                        return `${rollover.employee.individual.full_name} (${rollover.id})`;
                      }
                      return rollover.id;
                    },
                  },
                },
                {
                  path: "create",
                  async lazy() {
                    let { CreateRolloverRequest } = await import("~/views/admin-views");
                    return { Component: CreateRolloverRequest };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.rolloverRequests.create"),
                    meta: { title: { key: "breadcrumbs.rolloverRequests.create" } },
                  },
                },
              ],
            },
            {
              path: "/demos",
              async lazy() {
                let { CreateDemoPage } = await import("~/views/admin-views");
                return { Component: CreateDemoPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.demos"),
                meta: { title: { key: "breadcrumbs.demos" } },
              },
            },
            {
              path: "/distribution-requests",
              handle: {
                breadcrumb: t("breadcrumbs.distributionRequests.index"),
                meta: { title: { key: "breadcrumbs.distributionRequests.index" } },
              },

              children: [
                {
                  index: true,
                  async lazy() {
                    let { DistributionRequestsPage } = await import(
                      "~/components/super-admin/pages/distribution-requests"
                    );
                    return { Component: DistributionRequestsPage };
                  },
                },
                {
                  path: "create",
                  handle: {
                    breadcrumb: t("breadcrumbs.distributionRequests.create"),
                    meta: { title: { key: "breadcrumbs.distributionRequests.create" } },
                  },
                  async lazy() {
                    let { CreateDistributionRequest } = await import(
                      "~/views/admin-views"
                    );
                    return { Component: CreateDistributionRequest };
                  },
                },
                {
                  path: ":id",
                  loader: distributionRequestLoader(queryClient),
                  handle: {
                    meta: {
                      title: (distributionRequest) => ({
                        key: "breadcrumbs.distributionRequests.show",
                        options: {
                          distributionRequestId: distributionRequest.id,
                        },
                      }),
                    },
                    breadcrumb: (distribution) => {
                      if (distribution.individual) {
                        return `${distribution.individual?.full_name} (${distribution.id})`;
                      }
                      return distribution.id;
                    },
                  },
                  children: [
                    {
                      index: true,
                      async lazy() {
                        return {
                          Component: (
                            await import(
                              "~/components/super-admin/pages/distribution-requests/view-request"
                            )
                          ).ViewDistributionRequestPage,
                        };
                      },
                    },

                    {
                      path: "edit",
                      handle: {
                        breadcrumb: t("breadcrumbs.distributionRequests.edit"),
                        meta: { title: { key: "breadcrumbs.distributionRequests.edit" } },
                      },
                      async lazy() {
                        let { UpdateDistributionRequest } = await import(
                          "~/views/admin-views"
                        );
                        return { Component: UpdateDistributionRequest };
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "/loans",
              handle: {
                breadcrumb: t("breadcrumbs.loanRequests.index"),
                meta: { title: { key: "breadcrumbs.loanRequests.index" } },
              },
              element: <HiddenRouteGuard redirectTo="/" />,
              children: [
                {
                  index: true,
                  async lazy() {
                    let { LoanRequestsPage } = await import(
                      "~/components/super-admin/pages/loans"
                    );
                    return { Component: LoanRequestsPage };
                  },
                },
                {
                  path: ":loanId",
                  loader: loanDetailsLoader(queryClient),
                  handle: {
                    breadcrumb: (loan) => {
                      if (loan.individual) {
                        return `${loan.individual?.full_name} (${loan.u_id})`;
                      }
                      return loan.u_id;
                    },
                    meta: { title: { key: "breadcrumbs.loanRequests.loanDetail" } },
                  },
                  children: [
                    {
                      index: true,
                      async lazy() {
                        let { LoanDetailPage } = await import(
                          "~/components/super-admin/pages/loans/view-loan"
                        );
                        return { Component: LoanDetailPage };
                      },
                    },
                    {
                      path: "reamortization",
                      loader: loanDetailsLoader(queryClient),
                      handle: {
                        breadcrumb: "Reamortization",
                      },
                      async lazy() {
                        let { Reamortization } = await import(
                          "~/components/super-admin/pages/loans/reamortization"
                        );
                        return { Component: Reamortization };
                      },
                    },
                    {
                      path: "payoff",
                      loader: loanDetailsLoader(queryClient),
                      handle: {
                        breadcrumb: "Loan payoff",
                      },
                      async lazy() {
                        let { Payoff } = await import(
                          "~/components/super-admin/pages/loans/payoff"
                        );
                        return { Component: Payoff };
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
